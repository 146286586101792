import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import { 
    // Dropdown, ButtonToolbar, DropdownButton,
    // Form, 
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'
import _ from 'lodash'  //lodash for cloneDeep, camelCase

import axios from 'axios'  //for ajax get
// import { Map, Marker, Popup, Tooltip, GeoJSON, ImageOverlay, Polygon, ZoomControl } from 'react-leaflet'
import { Map, GeoJSON, ImageOverlay, ZoomControl } from 'react-leaflet'
import { Icon, DivIcon, CRS } from 'leaflet'  //Use individual Class instead of L.*, however cannot use on Marker 
import L from 'leaflet'

import Layout from '../../components/layout'
// import FactionsMap from '../../components/factions/factions-map'

import '../../css/map.css'

class RegionsEurope extends Component {
    constructor(props) {
        super(props)
        // this.polyGJRef = React.createRef();
        this.state = {
            // viewport: { center: [1000, 1700], zoom: -1  },
            viewport: { center: [1000, 1980], zoom: -1  }, // 2160, 3840
            // viewport: { center: [540, 960], zoom: 0  },
            sdIcon: null,

            iconFacAgurts: null,
            iconFacOmanga: null,
            iconFacGafia: null,
            iconFacSoilGhost: null,
            iconPtLarge: null,

            iconAreaZone: null,
            iconAreaCity: null,
            iconPtSmall: null,
            iconPtMont: null,
            iconPtVolcano: null,
            iconPtWater: null,

            sPointerIcon: null,
            sSuitcasePoint: null,

            gJsonPoint: null,
            gJsonPoly: null,
            error: null,
            i18nState: null,
            i18nLang: null
        }

        this.onEachFeaturePoint = this.onEachFeaturePoint.bind(this);
        this.pointToLayer = this.pointToLayer.bind(this);
    }

    // --- GeoJSON helper for each point (marker)--- //
    pointToLayer(geoJsonPoint, latlng) {
        // --- init variable --- //
        let markerType = 0; //0=Icon, 1=divIcon(Icon with Text)
        let blinkClsName = ' icnBlinking';  //CSS class name
        let tmpIcon = {};
        // --- init variable --- //

        // --- init function --- //
        // const initDivIcon = () => {  //divIcon
        //     // --- assign Icon by "placeType" using lodash cloneDeep method --- //
        //     tmpIcon = new DivIcon();
        //     tmpIcon = _.cloneDeep(this.state.sdIcon);
        // }

        // const initIcon = () => {  //Icon
        //     // --- assign Icon by "placeType" using lodash cloneDeep method --- //
        //     tmpIcon = new Icon({
        //         iconUrl: require('leaflet/dist/images/marker-icon.png'),
        //         // iconUrl: require('/asset/map/mapic_pt_s.png'),
        //         className: ''
        //     });
        //     tmpIcon = _.cloneDeep(this.state.sPointerIcon);
        // }
    

        // --- assign Icon Style by "style" using CSS className --- //
        const addStyleClass = () => {
            if (geoJsonPoint.properties['style']) {
                switch (geoJsonPoint.properties['style']) {
                    case 1: //blinking
                        tmpIcon.options.className += blinkClsName;
                        break;
                    case 2: //placeholder
                        tmpIcon.options.className += '';
                        break;
                    default:
                        break;
                }
            }
        }

        // --- assign CSS 'placeName' class & divIcon html to 'name' --- //
        const addPlaceNameClass = () => {
            if (geoJsonPoint.properties['name']) {
                // set className to camelCase of the place name
                let clsName = ' '+_.camelCase(geoJsonPoint.properties['name'])+'Class';
                // set i18n place name if available
                let txt = this.state.i18nState[geoJsonPoint.properties['name']] || geoJsonPoint.properties['name']; // for i18n translate
                switch (markerType) {
                    case 0: //Icon
                        // add className to the Icon
                        tmpIcon.options.className += clsName;
                        break;
                    case 1: //DivIcon
                        // add className to the divIcon
                        tmpIcon.options.className += clsName;
                        // divIcon have html properties
                        tmpIcon.options.html = txt;
                        break;
                    default:
                        break;
               }
            }
        }

        // --- init function --- //
        if (geoJsonPoint.properties) { // check properties exist
            // --- assign Icon by "placeType" using lodash cloneDeep method --- //
            switch (geoJsonPoint.properties['placeType']) {
                case 1:
                    markerType = 1			//divIcon
                    tmpIcon = new DivIcon()
                    tmpIcon = _.cloneDeep(this.state.iconFacAgurts)
                    break
                case 2:
                    markerType = 1			//divIcon
                    tmpIcon = new DivIcon()
                    tmpIcon = _.cloneDeep(this.state.iconFacOmanga)
                    break
                case 3:
                    markerType = 1			//divIcon
                    tmpIcon = new DivIcon()
                    tmpIcon = _.cloneDeep(this.state.iconFacGafia)
                    break
                case 4:
                    markerType = 1			//divIcon
                    tmpIcon = new DivIcon()
                    tmpIcon = _.cloneDeep(this.state.iconFacSoilGhost)
                    break
                case 1000:
                    markerType = 1			//divIcon
                    tmpIcon = new DivIcon()
                    tmpIcon = _.cloneDeep(this.state.iconAreaZone)
                    break
                case 1001:
                    markerType = 1			//divIcon
                    tmpIcon = new DivIcon()
                    tmpIcon = _.cloneDeep(this.state.iconAreaCity)
                    break
                case 10000:
                    markerType = 1			//divIcon
                    tmpIcon = new DivIcon()
                    tmpIcon = _.cloneDeep(this.state.iconPtLarge)
                    break
                case 10001:
                    markerType = 0			//Icon
                    tmpIcon = new Icon({
                        iconUrl: require('leaflet/dist/images/marker-icon.png'),
                        className: 'iconPtSmall'
                    })
                    tmpIcon = _.cloneDeep(this.state.iconPtSmall)
                    break
                case 10002:
                    markerType = 0			//Icon
                    tmpIcon = new Icon({
                        iconUrl: require('leaflet/dist/images/marker-icon.png'),
                        className: 'iconPtMont'
                    })
                    tmpIcon = _.cloneDeep(this.state.iconPtMont)
                    break
                case 10003:
                    markerType = 0			//Icon
                    tmpIcon = new Icon({
                        iconUrl: require('leaflet/dist/images/marker-icon.png'),
                        className: 'iconPtVolcano'
                    })
                    tmpIcon = _.cloneDeep(this.state.iconPtVolcano)
                    break
                case 10004:
                    markerType = 0			//Icon
                    tmpIcon = new Icon({
                        iconUrl: require('leaflet/dist/images/marker-icon.png'),
                        className: 'iconPtWater'
                    })
                    tmpIcon = _.cloneDeep(this.state.iconPtWater)
                    break

                default:
                    markerType = 0;
                    break;
            }
            addStyleClass();		// add Style to Icon
            addPlaceNameClass();	// add place name CSS Class
        }
        // return marker icon
        return L.marker(latlng, {icon: tmpIcon}); // Change the icon to default icon
    }


    // --- GeoJSON helper for each features (event/popup/tooltip) 
    onEachFeaturePoint(feature, layer) {
        layer.on({
            mouseover: this.highlightFeature.bind(this),
            mouseout: this.resetHighlight.bind(this)
        });

        if (feature.properties && feature.properties.url) {
            // enable click for marker
            let langLink = "/"+this.state.i18nLang+"/"+feature.properties.url; // for lang prefix URL
            // layer.on('click', function() { window.open(feature.properties.url) });
            if (feature.properties.url!=='#'){
                // console.log(`feature.properties.url = ${feature.properties.url}`)
                layer.on('click', function() { 
                    // alert(langLink) 
                    window.location.href=langLink
                    
                })
            }
        }
    }

    highlightFeature(e) {
        // var layer = e.target;
        if (e.target && e.target.feature.geometry.type.includes("Polygon")) {
            // console.log(e.target);
            e.target.setStyle({
                weight: 3,
                fillOpacity: 0.1,
                fill:true, 
                opacity:1,
                color:'#c8702b'
            });
        }
    }
    resetHighlight(e) {
        if (e.target && e.target.feature.geometry.type.includes("Polygon") && e.target._eventParents) {
            // this.polyGJRef.current.leafletElement.resetStyle(e.target);
            for (let i in e.target._eventParents) {
                e.target._eventParents[i].resetStyle(e.target); //not sure if _eventParents always present
            }
        }
    }


    // --- React componentDidMount to get JSON & set Leaflet Marker Icons --- //
    componentDidMount(){
        // --- use axios to get GeoJSON --- //
        let that = this;

        that.setState({
            i18nLang: that.props.pageContext.lng || "en"  // hack to get the language code
        });

        that.setState({
            i18nState: JSON.parse(that.props.data.locales.edges[0].node.data)  // hack for i18n per language content
        });

        axios.get('/static-assets/region1-point.json')
        .then(function (response) {
            // handle success
            // set GeoJSON to react state
            that.setState({
                gJsonPoint: response.data
            });
        })
        .catch(function (error) {
            // handle error
            that.setState({
                error
            });
        });

        axios.get('/static-assets/region1-polygon.json')
        .then(function (response) {
            // handle success
            // set GeoJSON to react state
            that.setState({
                gJsonPoly: response.data
            });
        })
        .catch(function (error) {
            // handle error
            that.setState({
                error
            });
        });
        // --- use axios to get GeoJSON --- //
        
        // --- set Marker Icon --- //
        this.setState({iconFacAgurts: new DivIcon({
                className: 'divIcon iconFacAgurts',  // use common.css to set the CSS of the Div
                html: '<b>iconFacAgurts</b>',
                iconSize: [40, 40], // div width & height
            })
        })
        this.setState({iconFacOmanga: new DivIcon({
                className: 'divIcon iconFacOmanga',  // use common.css to set the CSS of the Div
                html: '<b>iconFacAgurts</b>',
                iconSize: [40, 40], // div width & height
            })
        })
        this.setState({iconFacGafia: new DivIcon({
                className: 'divIcon iconFacGafia',  // use common.css to set the CSS of the Div
                html: '<b>iconFacGafia</b>',
                iconSize: [40, 40], // div width & height
            })
        })
        this.setState({iconFacSoilGhost: new DivIcon({
                className: 'divIcon iconFacSoilGhost',  // use common.css to set the CSS of the Div
                html: '<b>iconFacSoilGhost</b>',
                iconSize: [40, 40], // div width & height
            })
        })
        this.setState({iconAreaZone: new DivIcon({
                className: 'divIcon iconAreaZone',  // use common.css to set the CSS of the Div
                html: '<b>iconAreaZone</b>',
                iconSize: [30, 30], // div width & height
                // iconAnchor: [50, 25],
            })
        })
        this.setState({iconAreaCity: new DivIcon({
                className: 'divIcon iconAreaCity',  // use common.css to set the CSS of the Div
                html: '<b>iconAreaCity</b>',
                iconSize: [30, 30], // div width & height
                // iconAnchor: [50, 25],
            })
        })
        this.setState({iconPtLarge: new DivIcon({
                className: 'divIcon iconPtLarge',  // use common.css to set the CSS of the Div
                html: '<b>iconPtLarge</b>',
                iconSize: [30, 30], // div width & height
                iconAnchor: [15, 26],
            })
        })

        //// --- Init Icon (marker shown as picture) --- ////
        this.setState({iconPtSmall: new Icon({
                iconUrl: require('../../../src/images/map/mapic_pt_s.png'),
                iconRetinaUrl: require('../../../src/images/map/mapic_pt_s.png'),
                iconSize: [30, 30],
                iconAnchor: [15, 26],
                className: 'ptIcon iconPtSmall',
            })
        })
        this.setState({iconPtMont: new Icon({
                iconUrl: require('../../../src/images/map/mapic_pt_mont.png'),
                iconRetinaUrl: require('../../../src/images/map/mapic_pt_mont.png'),
                iconSize: [30, 30],
                // iconAnchor: [44, 22],
                className: 'ptIcon iconPtMont',
            })
        })
        this.setState({iconPtVolcano: new Icon({
                iconUrl: require('../../../src/images/map/mapic_pt_volcano.png'),
                iconRetinaUrl: require('../../../src/images/map/mapic_pt_volcano.png'),
                iconSize: [30, 30],
                // iconAnchor: [44, 22],
                className: 'ptIcon iconPtVolcano',
            })
        })
        this.setState({iconPtWater: new Icon({
                iconUrl: require('../../../src/images/map/mapic_pt_water.png'),
                iconRetinaUrl: require('../../../src/images/map/mapic_pt_water.png'),
                iconSize: [30, 30],
                // iconAnchor: [44, 22],
                className: 'ptIcon iconPtWater',
            })
        })
    }



    render() {
        const { gJsonPoint } = this.state;
        const { gJsonPoly } = this.state;
        // check if GeoJSON succuessfully loaded
        if (!gJsonPoint || !gJsonPoly) {
            return (<div className="loading"></div>);
        }

        if (typeof window !== 'undefined') {
            return (
                <NamespacesConsumer>
                {(t, { i18n }) => {
                    const lang = i18n.language.toLowerCase()
                    // console.log(`lang = ${lang}`)

                    return (
                        <Layout siteData={ { title:`${t("MU000")} | ${t("MU005")} | ${t("MU008")}`, url:this.props.location.href } }>
                            <div id="mapEurope">

                                <main role="main">
                                    <Map
                                        onViewportChanged={this.onViewportChanged}
                                        viewport={this.state.viewport}
                                        crs={CRS.Simple} //Coordinate Reference System using, Simple = x,y (y axis -> bottom to top)
                                        // maxBounds={[[0,0], [1820,3840]]} //restriction of viewport pan, better set to the size of the Map Image
                                        // maxBounds={[[0,0], [1080, 1920]]} //restriction of viewport pan, better set to the size of the Map Image
                                        maxBounds={[[0,0], [2160, 3840]]} //restriction of viewport pan, better set to the size of the Map Image
                                        minZoom={-1} //minimal zoom level
                                        maxZoom={0} //maximal zoom level
                                        attributionControl={false} //set false to hidden bottom-right-hand corner attr
                                        zoomControl={false}
                                    >
                                        <ZoomControl
                                            // position="bottomleft"
                                            // position="bottomright"
                                            position="topright"
                                        />
                                        <ImageOverlay
                                            // bounds={[[0,0], [1820,3840]]} //size of MAP image
                                            // bounds={[[0,0], [1080, 1920]]} //size of MAP image
                                            bounds={[[0,0], [2160, 3840]]} //size of MAP image
                                            // url="/static-assets/worldmap_EU.jpg"
                                            url="/static-assets/worldmap_europe.jpg"
                                            alt="EU Region"
                                            className="baseMapImage"
                                            pane="tilePane" // image project on the leaflet tilePane
                                            // attribution="&copy;Acid Rain World" // bottom-right-hand corner attr, can be empty
                                        />
                                        <GeoJSON
                                            // ref='test1'
                                            data={gJsonPoint}
                                            // className="test1Points"
                                            onEachFeature={this.onEachFeaturePoint}
                                            pointToLayer={this.pointToLayer}
                                            style={{}}
                                        />
                                        <GeoJSON
                                            // ref={this.polyGJRef}
                                            data={gJsonPoly}
                                            onEachFeature={this.onEachFeaturePoint}
                                            pointToLayer={this.pointToLayer}
                                            style={{fillOpacity: 0.01, color:'#71360b', opacity:0.1, weight:2 }}
                                        />
                                    </Map>


                                    <div className="mapMenu">
                                        <Container>
                                            <Row>
                                                <Col xs={12} md={5} lg={6} className="px-0 pr-md-3 d-flex align-items-center">
                                                    <div className="ptitle">{t('MAP001')}</div>
                                                    {/* <Dropdown className="dropdown-region" drop="up">
                                                        <Dropdown.Toggle className="w-100" variant="map">
                                                            {t('MAP001')}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="w-100" flip={false}>
                                                            <Dropdown.Item href={`/${lang}/map/europe`} active> {t('MAP001')} </Dropdown.Item>
                                                            <Dropdown.Item href={`/${lang}/map/europe`} > {t('MAP002')} </Dropdown.Item>
                                                            <Dropdown.Item href={`/${lang}/map/europe`} > {t('MAP003')} </Dropdown.Item>
                                                            <Dropdown.Item href={`/${lang}/map/europe`} > {t('MAP004')} </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown> */}
                                                </Col>
                                                <Col xs={12} md={7} lg={6} className="listlink px-0 d-flex align-items-center">
                                                    <Link to={`/${lang}/map/factions`} className="d-flex align-items-center"><span>{t('PT6300')}</span></Link>
                                                    <Link to={`/${lang}/map/places`} className="d-flex align-items-center"><span>{t('PT6500')}</span></Link>
                                                    <Link to={`/${lang}/map/events`} className="d-flex align-items-center"><span>{t('PT6700')}</span></Link>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </main>

                            </div>
                        </Layout>
                    )
                }}
                </NamespacesConsumer>
            )
        } // end if 

    }
}

export default withI18next()(RegionsEurope)

export const query = graphql`
    query getRegionsEuropeLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
