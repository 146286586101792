import React, { Component } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import _concat from "lodash/fp/concat";
import _uniq from "lodash/fp/uniq";
import _filter from "lodash/fp/filter";
import _map from "lodash/fp/map";
import _orderBy from "lodash/fp/orderBy";
import _flow from "lodash/fp/flow";

import {
    Breadcrumb, Form,
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../../util/get-cache-handler'
import { getTrans } from '../../util/get-trans-handler'
import { getAxiosArmies } from '../../util/get-axios-armies-handler'
import { getAxiosPlaces } from '../../util/get-axios-places-handler'
import { getAxiosPlaceType } from '../../util/get-axios-placetype-handler'

import Layout from '../../components/layout'
import FilterDropdown from '../../components/filter-dropdown'
import PlacesList from '../../components/map/places_list'



class PlacesListPage extends Component {

    constructor(props, context) {
        super(props, context);
        // Don't call this.setState() here!

        this.state = {
            trans: [],
            placesFullList: [],
            placesList: [],
            filterList: [],
            filterLabel: '',
            filterDisplay: '',
            sortOption: 'sort-latest',

            postsToShow:4,
            postsToLoad:4,
        }
        this.handleSorting = this.handleSorting.bind(this)
    }

    async componentDidMount() {

        let self = this

        /////// Initial translate for slug //////////
        const enList = (self.props.data.en.edges)
        let _t = await getTrans(enList)
        self.setState({trans: _t})

        /////// Setup Data //////////
        let placetypeslist = JSON.parse(localStorage.getItem('placetypeslist')) || undefined
        let placeslist = JSON.parse(localStorage.getItem('placeslist')) || undefined
        let armieslist = JSON.parse(localStorage.getItem('armieslist')) || undefined

        // Get Local Cache
        if ( typeof placetypeslist === 'undefined' || typeof placeslist === 'undefined' || typeof armieslist === 'undefined' || getCache() ) {
            // console.log(`load ajax`)
            placetypeslist = await getAxiosPlaceType({ o:1000 })
            placeslist = await getAxiosPlaces({ o:1000 })
            armieslist = await getAxiosArmies({ o:1000 })

            localStorage.setItem('placetypeslist', JSON.stringify(placetypeslist))
            localStorage.setItem('placeslist', JSON.stringify(placeslist))
            localStorage.setItem('armieslist', JSON.stringify(armieslist))

            localStorage.setItem('version', +(new Date()))
        }

        /////// Display Data //////////
        // Setup Faction List
        let factionslist =  ( armieslist ) ?  _.reject( armieslist, {'TypeName':'Division'} ) : null
        // console.log(`==== factionslist ====`,factionslist)

        // Setup Place List
        if ( (placeslist) && (placetypeslist) && (factionslist) ){

            let filterlist = []
            let _placeslist = []
            _.map( placeslist, (obj,i) => {
                // Filter Faction Points (TypeID : 1 ~ 999 )
                if (obj.TypeID>999){
                    let newobj = obj
                    newobj['typeobj'] = _.find(placetypeslist, {'PlaceTypeID': obj.TypeID  } )
                    _placeslist.push(newobj)

                    // filterlist = _.chain(filterlist)
                    // .concat(obj.Armies)
                    // .uniq()
                    // .value()
                    filterlist = _flow(
                        _concat(obj.Armies),
                        _uniq
                        )(filterlist)
                }
            })

            // _placeslist = _.chain(_placeslist)
            //             .filter( { 'DisplayType':'Marker' })
            //             .map( obj =>{
            //                 obj["NameEn"] = _t[obj.Name]
            //                 return obj
            //             })
            //             .orderBy(  ['DisplayOrder','PublishDate'], ['desc','desc'] )
            //             .value()

            _placeslist = _flow(
                _filter( { 'DisplayType':'Marker' } ),
                _map( obj => {
                        obj["NameEn"] = _t[obj.Name]
                        return obj
                    }),
                _orderBy( ['DisplayOrder','PublishDate'], ['desc','desc'] )
                )(_placeslist)
            // DEBUG :
            // console.log(`_placeslist ------>>>> `, _placeslist)

            // Set up filter list for dropdown ////
            let _filterlist = {}
            _.map( filterlist, (obj) => {
                let _id = Number(obj)
                let faction = _.find(factionslist, {'TypeName': 'Faction', 'ArmieID': _id} )
                // console.log(`factionslist.find('TypeName': 'Faction', 'ArmieID': obj) = ${faction}`)
                _filterlist[_id] = {
                    id: _id,
                    nameid : faction.Name,
                    logo : faction.Logo
                }
            })
            // console.log(_filterlist)
            // console.log(`_filterlist ------>>>> `, _filterlist)
            self.setState({
                placesFullList: _placeslist,
                placesList: _placeslist,
                filterList: _filterlist,
            })

        }

        window.addEventListener(`scroll`, this._handleScroll)
        let mq = document.getElementsByClassName('wrapper')[0].offsetWidth
        // console.log(`news-mount - mq = ${mq}`)
        if (mq>1138) this.setState({ postsToShow: 8, postsToLoad: 4 })
        else if (mq>718) this.setState({ postsToShow: 6, postsToLoad: 3 })
        else this.setState({ postsToShow: 4, postsToLoad: 2 })

    }

    componentWillUnmount() {
        window.removeEventListener(`scroll`, this._handleScroll)
    }

    _update() {
        //     // console.log(`news-update`)
        const distanceToBottom = document.querySelector('#footer .base').offsetTop
        if (distanceToBottom < window.scrollY+window.innerHeight  && this.state.postsToShow < _.size(this.state.placesList) ) {
            // console.log(`d to btm: ${distanceToBottom} < w.sY: ${window.scrollY} / ${window.scrollY+window.innerHeight}  && pts: ${this.state.postsToShow} < ${_.size(this.state.placesList)} `)
            this.setState({ postsToShow: this.state.postsToShow + this.state.postsToLoad })
        }
        this.ticking = false
    }

    _handleScroll = () => {
        // console.log(`news-scrollhandle`)
        if (!this.ticking) {
            this.ticking = true
            requestAnimationFrame(() => this._update())
        }
    }

    handleSorting = (e) => {
        // console.log(`handleSorting e.target.value = ${e.target.value}  ` )
        let _placeslist, set = []
        switch (e.target.value){
            case 'sort-az':
                set = [['NameEn'], ['asc']]
                break
            case 'sort-latest':
            default:
                set = [['DisplayOrder','PublishDate'], ['desc','desc']]
        }
        _placeslist = _.orderBy( this.state.placesList, set[0], set[1] )

        this.setState({
            sortOption: e.target.value ,
            placesList: _placeslist
        })
    }

    handleFilter = (ek) => {
        // console.log(`handleFilter eventkey value =  ${ek}`,  )
        // console.log(`this.state.placesFullList = `, this.state.placesFullList)

        let _placeslist = []
        let label = ''
        if (ek > 0){
            _.map( this.state.placesFullList, (obj,i) =>{
                // console.log( `data[${i}]["Armies"] = ${this.state.placesList[i]["Armies"]}` )
                this.state.placesFullList[i]["Armies"].forEach( (aid)=>{
                    // console.log(`data[${i}]["Armies"] = aid = ${aid} `)
                    if (aid===ek) {
                        label = this.state.filterList[aid]['name']
                        _placeslist.push(obj)
                    }
                })
            })
        }else{
            _placeslist = this.state.placesFullList
            label = this.state.filterLabel
        }
        this.setState({
            filterDisplay:label,
            placesList: _placeslist,
        })
    }



    render() {

        // DEBUG :
        // console.log(` ==== this.props : places list page ==== `)
        // console.log( this.props )
        // console.log(this.state.placesList)
        const lang = this.props.pageContext.lng


        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                // const lang = i18n.language.toLowerCase()
                if (_.size(this.state.filterList)>0 && this.state.filterLabel==='' && this.state.filterDisplay===''){
                    _.map(this.state.filterList, obj =>{
                        obj['name'] = t(obj.nameid)
                    })
                    this.setState({
                        filterLabel: t('PT0200'),
                        filterDisplay:t('PT0200'),
                    })
                }

                return (

                    <Layout siteData={ { title:`${t("MU000")} | ${t("MU005")} | ${t("MU008")} | ${t("PT6500")}`, url:this.props.location.href } }>
                        <div id="PlacesList" className="body-page ">
                            <Container className="wrapper line-head">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={`/${lang}/map/`}> {t('MU008')} ></Breadcrumb.Item>
                                </Breadcrumb>
                            </Container>

                            <main role="main" className="main">
                                <Container className="wrapper">
                                    <Row>
                                        <Col xs={6} md={12} className="pr-0">
                                            <div className="ptitle">{t('PT6500')}</div>
                                        </Col>
                                        <Col xs={6} className="pl-0 text-right order-md-2 ">
                                            {
                                                (this.state.filterList==null) ?
                                                    null
                                                    :
                                                    <FilterDropdown mode="faction" seltext={this.state.filterDisplay} trans={this.state.trans}
                                                        filterlist={this.state.filterList} handleFilter={this.handleFilter.bind(this)} />
                                            }
                                        </Col>
                                        <Col xs={12} md={6} className="order-md-1 pt-3">
                                            <Form className="form-sorting">
                                                <Form.Label>{t('PT0100')}</Form.Label>
                                                <Form.Check inline label={t('PT0101')} name="sorting" type="radio" value="sort-az"
                                                    onChange={this.handleSorting} checked={this.state.sortOption === 'sort-az'}/>
                                                <Form.Check inline label={t('PT0102')} name="sorting" type="radio" value="sort-latest"
                                                    onChange={this.handleSorting} checked={this.state.sortOption === 'sort-latest'}/>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <hr className="d-none d-md-block"/>
                                </Container>

                                <Container className="wrapper">
                                {
                                    (this.state.placesList==null) ?
                                        <div className="loading"></div>
                                        :
                                        <PlacesList placeslist={this.state.placesList} pts={this.state.postsToShow}/>
                                }
                                </Container>

                            </main>

                        </div>
                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(PlacesListPage)

export const query = graphql`
    query getPlacesListPageLang($lng: String!) {

        locales: allLocale(filter: { lng: { eq: $lng } , ns: { eq: "messages" } }) {
            ...LocaleFragment
        }

        en: allLocale(filter: { lng: { eq: "en", }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
