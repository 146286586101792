import React, { Component } from 'react'
import _ from 'lodash'
import {
    Card
} from 'react-bootstrap'
// import { StaticQuery, graphql, Link } from 'gatsby'
// import Moment from 'react-moment'
import { withNamespaces } from 'react-i18next'

// import PrismicHTML from '../prismic-html'

class ProductCard extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            url: this._getUrl(),
            tagList: {
                1 : {
                    "type":     "preorder",
                    "en":       "Pre Order Now",
                    "zh-hk":    "接受預定",
                    "zh-cn":    "接受预定",
                },
                2 : {
                    "type":     "available",
                    "en":       "Available",
                    "zh-hk":    "現已上市",
                    "zh-cn":    "首次上市",
                },
            }
        }

    }

    componentDidMount() {
    }

    componentWillUnmount() {

    }

    shouldComponentUpdate() {
        return true
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.item.id !== this.props.item.id) {
            this.setState({
                url: this._getUrl()
            })
        }
    }

    _getUrl() {
        let _url = ""
        const item = this.props.item
        const enPds = this.props.enProducts
        if (item.lang === 'en-gb') {
            _url = `${item.data.product_name.text.match(/[A-Za-z0-9]+/g).join('_').toLowerCase()}`
        } else {
            let enId = _.filter(item.alternate_languages, {'lang': 'en-gb'})[0].id
            let enPd = _.find(enPds, {id: enId})
            _url = `${enPd.data.product_name.text.match(/[A-Za-z0-9]+/g).join('_').toLowerCase()}`
        }
        return _url
    }

    _getLabel(label_type){
        switch( label_type ) {
            case '1':     return ' preorder';
            case '2':     return ' available';
            default:    return '';
        }
    }

    render() {
        // const t = this.props.t
        const product = this.props.item
        const productsSlug = this.state.url
        // const tagLabel = this._getLabel(product.data.label_type)
        // console.log(`product.data.label_type = ${product.data.label_type} ( ${typeof +product.data.label_type} )`)
        const tagType = (+product.data.label_type > 0) ? this.state.tagList[(+product.data.label_type)] : null

        return (
            <>
                {/* <Card key={product.id} data-product-type={t(product.data.type_id)} onClick={() => window.location.href=`/${this.props.lng}/products/${productsSlug}`}> */}
                <a className="card" href={`/${this.props.lng}/products/${productsSlug}`} target="_blank" rel="noreferrer">
                    {
                        (tagType) ?
                            <div className={`tag ${tagType.type}`}>
                                <div className={this.props.lng}>{tagType[this.props.lng]}</div>
                            </div>
                            :
                            null
                    }
                    <Card.Img variant="top" src={product.data.photos[0].img.url} />
                    <Card.Body>
                        <Card.Text>{product.data.product_name.text}</Card.Text>
                    </Card.Body>
                </a>
                {/* </Card> */}
            </>
        )
    } 
}

export default withNamespaces()(ProductCard)
 