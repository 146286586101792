import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import {
  Container, Row, Col,
} from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
// import { Link } from '@sckit/gatsby-plugin-i18next'
import _ from 'lodash'
import { MD5 } from 'object-hash'

import { convPrismicLng } from '../../util/prismic-i18n-handler'

import LocalImage from '../local-image'

const _getStoryUrl = (story) => {
    const seasonId = story.data.seasonid
    const chapterId = story.data.chapterid
    // const title = story.data.title.text

    return MD5(`season ${seasonId} CHAPTER ${chapterId} RAINSTORM AT THE FRONTIER Session 1.1 The Taste of Coffee`).substring(0, 8)
    // return MD5(`season ${seasonId} CHAPTER ${chapterId} ${title}`).substring(0, 8)
}

const _createBoxElem = ({col, item}) => {
    let attr = (item.type === 'web' || item.type === 'story' ) ? [ '_blank', 'noopener '] : ['','']
    return (
        <Col md={6} key={`featurebox-c-${col}`} className="px-0">
          <div className="feat_box d-flex align-items-end"
            style={{ backgroundImage: `url(${item.img})` }} >

            <div className="head_bg d-flex bd-highlight align-items-end">
                <a href={item.link} className="w-100 d-flex bd-highlight align-items-end" target={`${attr[0]}`} rel={`${attr[1]}`}>
                    <div className="head flex-grow-1 bd-highlight">
                        <div className="type">{item.type}</div>
                        <div className="title">{item.title}</div>
                    </div>
                    <div className="more bd-highlight">
                        <LocalImage name="more" attr={{className: " ", alt:"Show More"}} />
                        {
                            // (item.type === 'web' || item.type === 'story' ) ?
                            //     <a href={item.link}><LocalImage name="more" attr={{className: " ", alt:"Show More"}} /></a>
                            //     :
                            //     <Link to={item.link}><LocalImage name="more" attr={{className: " ", alt:"Show More"}} /></Link>
                        }
                    </div>
                </a>
            </div>
          </div>
        </Col>
    )
}

const _getBoxItemTitle = (item) => {
    const itemTitle = (
        item.link.document === null ||
        item.link.document === undefined ||
        item.link.document.length <= 0
    ) ?
        item.captions.text
    :
        (typeof item.link.document[0].data === 'undefined' || item.link.type === 'product_detail' || item.link.type === 'news') ?
            item.captions.text
        :
            item.link.document[0].data.title.text
    // console.log(`itemTitle = ${itemTitle} , item = `, item )
    return itemTitle
}

const _getBoxItemLink = (link, data) => {
    let _r = ""
    if (link.link_type === "Web") {
        _r = link.url
    } else if (link.link_type === "Document") {
        switch (link.type) {
            case "news":                
                let linktype = (link.document[0].data.type.includes("codex")) ? "codex" : "news"
                if (link.lang === "en-gb") {
                    _r = `/en/${linktype}/${_.find(data.en, {id: link.id}).data.title.text.match(/[A-Za-z0-9]+/g).join('-').toLowerCase()}`
                } else {
                    let altLngs = _.find(data.all, {prismicId: link.id}).alternate_languages
                    let enLngId = _.filter(altLngs, { 'lang': 'en-gb' })[0].id
                    _r = `/${link.lang}/${linktype}/${_.find(data.en, {id: enLngId}).data.title.text.match(/[A-Za-z0-9]+/g).join('-').toLowerCase()}`
                }
                break;
            case "story_chapter":
                if (link.lang === "en-gb") {
                    // TODO: get book name from prismic
                    let slug = _getStoryUrl(_.find(data.en, {id: link.id}))
                    _r = `https://story.aither.world/book/acid-rain-world/${slug}`
                } else {
                    let altLngs = _.find(data.all, {prismicId: link.id}).alternate_languages
                    let enLngId = _.filter(altLngs, { 'lang': 'en-gb' })[0].id
                    let slug = _getStoryUrl(_.find(data.en, {id: enLngId}))
                    // Debug:
                    // console.log(slug)
                    _r = `https://story.aither.world/book/acid-rain-world/${slug}`
                }
                break;
            case "product_detail":
                if (link.lang === "en-gb") {
                    _r = `/en/products/${_.find(data.en, {id: link.id}).data.title.text.match(/[A-Za-z0-9]+/g).join('_').toLowerCase()}`
                } else {
                    let altLngs = _.find(data.all, {prismicId: link.id}).alternate_languages
                    let enLngId = _.filter(altLngs, { 'lang': 'en-gb' })[0].id
                    _r = `/${link.lang}/products/${_.find(data.en, {id: enLngId}).data.title.text.match(/[A-Za-z0-9]+/g).join('_').toLowerCase()}`
                }
                break;
            default:
        }
        
    }

    return _r
}

const BoxWeb = ({ t, col, item }) => {
    let _item = {
        title: _getBoxItemTitle(item),
        type: "Web",
        img: item.banner_image.url || "",
        link: _getBoxItemLink(item.link)
    }
    return _createBoxElem({
        col: col,
        item: _item
    })
}

const BoxStory = ({ t, col, item, data }) => {
    let _item = {
        title: _getBoxItemTitle(item),
        type: t('TEXT180030'),
        img: item.banner_image.url || "",
        link: _getBoxItemLink(item.link)
    }
    return _createBoxElem({
        col: col,
        item: _item
    })
}

const BoxNews = ({ t, col, item, data }) => {
    let _item = {
        title: _getBoxItemTitle(item),
        type: t('MU003'),
        img: item.banner_image.url || "",
        link: _getBoxItemLink(item.link, data)
    }
    // Debug:
    // console.log(_item)
    return _createBoxElem({
        col: col,
        item: _item
    })
}

const BoxCodexs = ({ t, col, item, data }) => {
    let _item = {
        title: _getBoxItemTitle(item),
        type: t('MU006'),
        img: item.banner_image.url || "",
        link: _getBoxItemLink(item.link, data)
    }
    // Debug:
    // console.log(_item)
    return _createBoxElem({
        col: col,
        item: _item
    })
}

const BoxProduct = ({ t, col, item, data }) => {
    let _item = {
        title: _getBoxItemTitle(item),
        type: t('TEXT180031'),
        img: item.banner_image.url || "",
        link: _getBoxItemLink(item.link, data)
    }
    // Debug:
    // console.log(_item)
    return _createBoxElem({
        col: col,
        item: _item
    })
}

class FeatureBox extends Component {
    constructor(props) {
        super(props)
        // Don't call this.setState() here!
        this.lng = convPrismicLng(this.props.lng)
        this.limit = this.props.limit || 1
        this.displayIn = this.props.displayIn || 1
    }

    render () {
        return (
            // ========= FYI: old limit =========
            // featurebox: allPrismicFeaturebox(
            //     sort: {fields: first_publication_date, order:DESC}
            //     ,limit: 6  // = no. of language
            // )
            // topnews: allPrismicNews(
            //     sort: {fields: data___datetime, order: DESC}
            //     ,limit: 30  // = no. of top news of all language
            // )
            // topnewsEn: allPrismicNews(
            //     filter: { lang: { eq: "en-gb" } },
            //     sort: {fields: data___datetime, order: DESC}
            //     ,limit: 10  // = no. of top news of english
            // )
            // latestStory: allPrismicStoryChapter(
            //     sort: {fields: [data___seasonid, data___chapterid], order: DESC}
            //     ,limit: 30  // = no. of latest story of all language
            // )
            // latestStoryEn: allPrismicStoryChapter(
            //     filter: {lang: {eq: "en-gb"}},
            //     sort: {fields: [data___seasonid, data___chapterid], order: DESC}
            //     ,limit: 10  // = no. of latest story of english
            // )
            // latestProduct: allPrismicProductDetail(
            //     sort: {fields: [data___display_order], order: DESC}
            //     ,limit: 60    // = no. of latest display order product of all language
            // )
            // latestProductEn: allPrismicProductDetail(
            //     filter: {lang: {eq: "en-gb"}},
            //     sort: {fields: [data___display_order], order: DESC}
            //     ,limit: 20  // = no. of latest display order product of english
            // )
            <StaticQuery
                query = {graphql`
                    {
                        featurebox: allPrismicFeaturebox(
                            sort: {fields: first_publication_date, order:DESC}
                        ) {
                            edges {
                                node {
                                    prismicId
                                    lang
                                    data {
                                        featurebox {
                                            display_order
                                            banner_image {
                                                url
                                            }
                                            captions {
                                                text
                                            }
                                            link {
                                                id
                                                url
                                                type
                                                lang
                                                link_type
                                            }
                                            target
                                        }
                                    }
                                }
                            }
                        }

                        topnews: allPrismicNews(
                            sort: {fields: data___datetime, order: DESC}
                        ) {
                            edges {
                                node {
                                    prismicId
                                    alternate_languages {
                                        id
                                        lang
                                    }
                                    data {
                                        title {
                                            text
                                        }
					type
                                    }
                                }
                            }
                        }

                        topnewsEn: allPrismicNews(
                            filter: { lang: { eq: "en-gb" } },
                            sort: {fields: data___datetime, order: DESC}
                        ) {
                            edges {
                                node {
                                    id: prismicId
                                    data {
                                        title {
                                            text
                                        }
					type
                                    }
                                }
                            }
                        }

                        latestStory: allPrismicStoryChapter(
                            sort: {fields: [data___seasonid, data___chapterid], order: DESC}
                        ) {
                            edges {
                                node {
                                    prismicId
                                    alternate_languages {
                                        id
                                        lang
                                    }
                                    data {
                                        title: chaptertitle {
                                            text
                                        }
                                    }
                                }
                            }
                        }

                        latestStoryEn: allPrismicStoryChapter(
                            filter: {lang: {eq: "en-gb"}},
                            sort: {fields: [data___seasonid, data___chapterid], order: DESC}
                        ) {
                            edges {
                                node {
                                    id: prismicId
                                    data {
                                        seasonid
                                        chapterid
                                        title: chaptertitle {
                                            text
                                        }
                                    }
                                }
                            }
                        }

                        latestProduct: allPrismicProductDetail(
                            sort: {fields: [data___display_order], order: DESC}
                        ) {
                            edges {
                                node {
                                    prismicId
                                    alternate_languages {
                                        id
                                        lang
                                    }
                                    data {
                                        title: product_name {
                                            text
                                        }
                                    }
                                }
                            }
                        }

                        latestProductEn: allPrismicProductDetail(
                            filter: {lang: {eq: "en-gb"}},
                            sort: {fields: [data___display_order], order: DESC}
                        ) {
                            edges {
                                node {
                                    id: prismicId
                                    data {
                                        title: product_name {
                                            text
                                        }
                                    }
                                }
                            }
                        }
                    }
                    `}
                    render = {data => {
                        const _features = data.featurebox.edges
                        const _topnews = data.topnews.edges.map(e => e.node)
                        const _latestStory = data.latestStory.edges.map(e => e.node)
                        const _latestProduct = data.latestProduct.edges.map(e => e.node)
                        const _topnewsEn = data.topnewsEn.edges.map(e => e.node)
                        const _latestStoryEn = data.latestStoryEn.edges.map(e => e.node)
                        const _latestProductEn = data.latestProductEn.edges.map(e => e.node)
                        const curLngFeatures = _.find(_features, ({node}) => node.lang === `${this.lng}`).node
                        let totalCol = this.displayIn
                        let totalRow = Math.ceil(this.limit / totalCol)
                        let rowData = []
                        // console.log(curLngFeatures)

                        var offset = 0
                        for (var row = 0; row < totalRow; row++) {
                            let colData = []
                            for (var col = 0; col < totalCol; col++ ) {
                                let numOfItem = (row + col + offset)
                                let featureItem = curLngFeatures.data.featurebox[numOfItem]
                                // DEBUG : 
                                // console.log(`featureItem ( ${featureItem.link.link_type } ) = `, featureItem)
                                if (featureItem === undefined) break

                                // TODO: create <Box{Type} />
                                let _box = null
                                if (featureItem.link.link_type === "Document") {
                                    switch (featureItem.link.type) {
                                        case "news":
                                            if (featureItem.link.document[0].data.type.includes("codex"))
                                                _box = <BoxCodexs key={`BoxCodex-${numOfItem}`} col={col} item={featureItem} data={{all: _topnews, en: _topnewsEn}} {...this.props} />
                                            else
                                                _box = <BoxNews key={`BoxNews-${numOfItem}`} col={col} item={featureItem} data={{all: _topnews, en: _topnewsEn}} {...this.props} />
                                            break;
                                        case "story_chapter":
                                            _box = <BoxStory key={`BoxStory-${numOfItem}`} col={col} item={featureItem} data={{all: _latestStory, en: _latestStoryEn}} {...this.props} />
                                            break;
                                        case "product_detail":
                                            _box = <BoxProduct key={`BoxProduct-${numOfItem}`} col={col} item={featureItem} data={{all: _latestProduct, en: _latestProductEn}} {...this.props} />
                                            break;
                                        default:

                                    }
                                } else {
                                    // assume link_type === "Web"
                                    _box = <BoxWeb key={`BoxWeb-${numOfItem}`} col={col} item={featureItem} />
                                }

                                colData.push(_box)
                            }
                            rowData.push(<Row key={`featurebox-r-${row}`}>{colData}</Row>)
                            offset++
                        }

                        return (
                            <div id="feature">
                                <Container className="wrapper">
                                    {rowData}
                                </Container>
                            </div>
                        )
                    }}
                    />
            )
    }
}

FeatureBox.propTypes = {
    limit: PropTypes.number,
    displayIn: PropTypes.number,
    lng: PropTypes.string
}

export default withNamespaces()(FeatureBox)
