import React from 'react'
import { graphql } from "gatsby"
import {
    Row, Col,
  Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import Layout from '../components/layout'

import './../css/pages.css'

const PolicyPage = () => (
    <NamespacesConsumer>
    {(t, { i18n }) => {
        // console.log(i18n)
        return (
            // <Layout siteData={ { title:`${t("TEXT000109")} | ${t("TEXT000015")}`, url:this.props.location.href } }>
            <Layout siteData={ { title:`${t("TEXT000109")} | ${t("TEXT000130")}` } }>
                <div id="bodyLaw" className="body-page ">
                    <Container className="wrapper policy-section">
                        <Row>
                            <Col>
                                <main role="main" className="main">
                                    <article className="content">
                                        <h2 id="Privacy-Policy">Privacy Policy</h2>
                                        <br />
                                        <h6 id="Last-updated-17th-July-2017">Last updated: 17th July, 2017</h6>
                                        <p>
                                            This privacy policy describes the treatment of information provided or collected on the <a href="http://www.acidrainworld.com" alt="www.acidrainworld.com" target="_blank" rel="noopener noreferrer">http://www.acidrainworld.com</a> website (the &ldquo;Site&rdquo;). It also explains the treatment of information provided or collected on applications we make available on third-party sites or platforms if disclosed to you in connection with use of the application. We follow this privacy policy in accordance with local law in the places where we operate.
                                        </p>

                                        <h3 id="Social-media">Social media</h3>
                                        <p>
                                            You can engage with our content and other offerings, such as videos, games and applications, on or through third-party social media sites, plug-ins and applications, such as those offered by Facebook and others. You may also choose to link your account with us to third party social media sites. When you link your account or engage with our content on or through third party social media sites, services, plug-ins, or applications you may allow us to have ongoing access to certain information from your social media account (e.g., name, e-mail address, profile photo, gender, birthday, location, your list of friends and their contact information, people you follow and/or who follow you, the posts or the ‘likes’ you make). We may also receive non-personally identifiable information (e.g., content viewed, game performance, high scores, and information about advertisements within the content you have been shown or may have clicked on, etc.) from your interaction with our content.
                                        </p>
                                        <p>
                                            When you provide information to a Site from your social media account, it can help enable us to do things like(i) give you exclusive content, (ii) personalize your online experience with us within and outside our Sites, and(iii) contact you through the social media sites or directly with the latest news, special offerings, and rewards.By providing such information, you consent to the use of the information in accordance with this Privacy Policy.
                                        </p>
                                        <p>
                                            If you post information or content, such as by posting photos, commenting on a blog, or participating in online forums or communities at our Sites, or when you interact with our Sites through social media sites, plug-ins or other applications, depending on your privacy settings, this information may become public on the Internet. Therefore, we cannot prevent further use of this information. You can control what data you share through privacy settings available on some social media sites. For more information about how you can customize your privacy settings and how third party social media sites handle your personally identifiable information, please refer to their privacy help guides, privacy policies and terms of use.
                                        </p>

                                        <h3 id="What-personal-information-do-we-collect-from-the-people-that-visit-our-blog-website-or-app">What personal information do we collect from the people that visit our blog, website or app?</h3>
                                        <p>
                                            When ordering or registering on our site, as appropriate, you may be asked to enter your email address or other details to help you with your experience.
                                        </p>

                                        <h3 id="When-do-we-collect-information">When do we collect information?</h3>
                                        <p>
                                            We collect information from you when you subscribe to a newsletter or enter information on our site.
                                        </p>

                                        <h3 id="How-do-we-use-your-information">How do we use your information?</h3>
                                        <p>
                                            We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
                                        </p>
                                        <ul>
                                            <li>To improve our website in order to better serve you.</li>
                                            <li>To allow us to better service you in responding to your customer service requests.</li>
                                            <li>To administer a contest, promotion, survey or other site feature.</li>
                                        </ul>
                                        <h3 id="How-do-we-protect-your-information">How do we protect your information?</h3>
                                        <p>We do not use vulnerability scanning and/or scanning to PCI standards.</p>
                                        <p>We only provide articles and information. We never ask for credit card numbers. We use regular Malware Scanning.</p>
                                        <p>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.</p>
                                        <p>We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.</p>
                                        <p>All transactions are processed through a gateway provider and are not stored or processed on our servers.</p>

                                        <h3 id="Do-we-use-‘cookies’">Do we use ‘cookies’?</h3>
                                        <p>
                                            Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
                                        </p>

                                        <h4 id="We-use-cookies-to">We use cookies to:</h4>
                                        <ul>
                                            <li>Help remember and process the items in the shopping cart.</li>
                                            <li>Understand and save user’s preferences for future visits.
                                                <ul>
                                                    <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p>
                                            You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser’s Help Menu to learn the correct way to modify your cookies.
                                        </p>
                                        <p>
                                            If you turn cookies off, Some of the features that make your site experience more efficient may not function properly.It won’t affect the user’s experience that make your site experience more efficient and may not function properly.
                                        </p>

                                        <h3 id="Third-party-disclosure">Third-party disclosure</h3>
                                        <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</p>

                                        <h3 id="Third-party-links">Third-party links</h3>
                                        <p>
                                            Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
                                        </p>

                                        <h3 id="Google">Google</h3>
                                        <p>
                                            Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users. <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en" target="_blank" alt="adwordspolicy" rel="noopener noreferrer">https://support.google.com/adwordspolicy/answer/1316548?hl=en</a>
                                        </p>
                                        <p>We have not enabled Google AdSense on our site but we may do so in the future.</p>

                                        <h3 id="How-does-our-site-handle-Do-Not-Track-signals">How does our site handle Do Not Track signals?</h3>
                                        <p>We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</p>
                                        
                                        <h3 id="Does-our-site-allow-third-party-behavioral-tracking">Does our site allow third-party behavioral tracking?</h3>
                                        <p>It’s also important to note that we allow third-party behavioral tracking</p>

                                        <h3 id="COPPA-Children-Online-Privacy-Protection-Act">COPPA (Children Online Privacy Protection Act)</h3>
                                        <p>When it comes to the collection of personal information from children under the age of 13 years old, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States’ consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children’s privacy and safety online.</p>
                                        <p>We do not specifically market to children under the age of 13 years old.</p>

                                        <h3 id="Fair-Information-Practices">Fair Information Practices</h3>
                                        <p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>

                                        <h3 id="In-order-to-be-in-line-with-Fair-Information-Practices-we-will-take-the-following-responsive-action-should-a-data-breach-occur">In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</h3>
                                        <p>We will notify you via email</p>
                                        <ul>
                                            <li>Within 7 business days</li>
                                        </ul>
                                        <p>We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>

                                        <h3 id="CAN-SPAM-Act">CAN SPAM Act</h3>
                                        <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>

                                        <h3 id="We-collect-your-email-address-in-order-to">We collect your email address in order to:</h3>
                                        <ul>
                                            <li>Send information, respond to inquiries, and/or other requests or questions</li>
                                        </ul>

                                        <h3 id="To-be-in-accordance-with-CANSPAM-we-agree-to-the-following">To be in accordance with CANSPAM, we agree to the following:</h3>
                                        <ul>
                                            <li>Not use false or misleading subjects or email addresses.</li>
                                            <li>Identify the message as an advertisement in some reasonable way.</li>
                                            <li>Include the physical address of our business or site headquarters.</li>
                                            <li>Monitor third-party email marketing services for compliance, if one is used. • Honor opt-out/unsubscribe requests quickly.</li>
                                            <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
                                        </ul>

                                        <h3 id="If-at-any-time-you-would-like-to-unsubscribe-from-receiving-future-emails-you-can-email-us-at">If at any time you would like to unsubscribe from receiving future emails, you can email us at</h3>
                                        <ul>
                                            <li>Follow the instructions at the bottom of each email.<br />and we will promptly remove you from ALL correspondence.</li>
                                        </ul>

                                        <h2 id="Contacting-Us">Contacting Us</h2>
                                        <p>If there are any questions regarding this privacy policy, you may contact us</p>
                                    </article>
                                </main>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </Layout>
        )
    }}
    </NamespacesConsumer>
)

export default withI18next()(PolicyPage)

export const query = graphql`
query PolicyLng($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
}
`
