import React, { Component } from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import _ from 'lodash'
import _head from "lodash/fp/head";
import _reject from "lodash/fp/reject";
import _orderBy from "lodash/fp/orderBy";
import _flow from "lodash/fp/flow";

import {
    Breadcrumb, 
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next, Link } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../util/get-cache-handler'
// import { getTrans } from '../util/get-trans-handler'
import { getAxiosPlaces } from '../util/get-axios-places-handler'
import { getAxiosPlaceType } from '../util/get-axios-placetype-handler'
import { getAxiosEvents } from '../util/get-axios-events-handler'

import NavBtm from '../components/navbtm'
import LocalImage from '../components/local-image'
import Share from '../components/share'
import Layout from "../components/layout"


class PlacePost extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            place : [],
            ptypeList : [],
            eventsList : [],

            postsToShow:4,
            postsToLoad:4,
            indexList: {},
        };
    }

    async componentDidMount() {

        let self = this
        // console.log(`self = `, self)
        
        /////// Setup Data //////////
        let placetypeslist = JSON.parse(localStorage.getItem('placetypeslist')) || undefined
        let placeslist = JSON.parse(localStorage.getItem('placeslist')) || undefined
        let eventslist = JSON.parse(localStorage.getItem('eventslist')) || undefined


        // Get Local Cache
        if ( typeof placetypeslist === 'undefined' || typeof placeslist === 'undefined' || typeof eventslist === 'undefined' || getCache() ) {
            // console.log(`load ajax`)
            placetypeslist = await getAxiosPlaceType({ o:1000 })
            placeslist = await getAxiosPlaces({ o:1000 })
            eventslist = await getAxiosEvents({ o:1000 })

            localStorage.setItem('placetypeslist', JSON.stringify(placetypeslist))
            localStorage.setItem('placeslist', JSON.stringify(placeslist))
            localStorage.setItem('eventslist', JSON.stringify(eventslist))

            localStorage.setItem('version', +(new Date()))
        }
        
        /////// Display Data //////////
        if ( (placeslist) && (placetypeslist) && (eventslist) ){
            // console.log(`==== GOT getAxiosPlaces ==== placeslist = `, placeslist)
            
            let place = _.find( placeslist, {'PlaceID': self.props.pageContext.placeId })
            // console.log(`self >> self.props.pageContext.placeId  >> `, self.props.pageContext.placeId)
            
            
            if (place){

                // Set up event list(eid as key) 
                let _eventslist = {}
                _.map( eventslist, (obj) => { 
                    _eventslist[obj.EventID] = obj
                })
    
                // Set up place type list (eid as key)
                let _typelist = {}
                _.map( placetypeslist, (obj) => { 
                    _typelist[obj.PlaceTypeID] = obj
                })

                self.setState({ 
                    eventsList: _eventslist, 
                    ptypeList: _typelist,
                    place: place,
                })
            }
        }


        window.addEventListener(`scroll`, this._handleScroll)
        let mq = document.getElementsByClassName('wrapper')[0].offsetWidth
        // console.log(`news-mount - mq = ${mq}`)
        if (mq>1138) this.setState({ postsToShow: 8, postsToLoad: 4 })
        else if (mq>718) this.setState({ postsToShow: 6, postsToLoad: 3 })
        else this.setState({ postsToShow: 4, postsToLoad: 2 })

    }


    componentWillUnmount() {
        window.removeEventListener(`scroll`, this._handleScroll)
    }

    _update() {
        //     // console.log(`news-update`)
        const distanceToBottom = document.querySelector('#footer .base').offsetTop
        if (distanceToBottom < window.scrollY+window.innerHeight  && this.state.postsToShow < _.size(this.state.indexList) ) {
            // console.log(`d to btm: ${distanceToBottom} < w.sY: ${window.scrollY} / ${window.scrollY+window.innerHeight}  && pts: ${this.state.postsToShow} < ${_.size(this.state.indexList)} `)
            this.setState({ postsToShow: this.state.postsToShow + this.state.postsToLoad })
        }
        this.ticking = false
    }

    _handleScroll = () => {
        // console.log(`news-scrollhandle`)
        if (!this.ticking) {
            this.ticking = true
            requestAnimationFrame(() => this._update())
        }
    }


    render() {
        let _t = JSON.parse(this.props.data.en.edges[0].node.data)
        const slug = this.props.pageContext.slugId
        const indexlist = this.props.pageContext.placeIndex
        let place = (this.state.place) ? this.state.place : this.props.pageContext.place

        // DEBUG :
        // console.log(`==== Place : this.props ==== `)
        // console.log(this.props)
        // console.log(`place / this.props.pageContext.place = `, place)
        
        // Handle places list for read more /////
        // let _indexlist = _.chain(indexlist)
        //                 .reject( {'id': place.PlaceID} )
        //                 .orderBy( ['slug'], ['asc'] )
        //                 .value()
        let _indexlist = []
        _.map( indexlist, (obj) => {
            // Filter Faction Points (TypeID : 1 ~ 999 )
            if (obj.id>999){
                _indexlist.push(obj)
            }
        })
        // _indexlist = _.chain(_indexlist)
        //     .reject( {'id': place.PlaceID} )
        //     .orderBy( ['slug'], ['asc'] )
        //     .value()
        _indexlist = _flow(
            _reject({'id': place.PlaceID}),
            _orderBy( ['slug'], ['asc'] )
          )(_indexlist)

        if (_.size(this.state.indexList)===0)
            this.setState({ indexList: _indexlist })
        
        // Set latest record slug /////
        // const latestslug = _.chain(_indexlist)
        //                 .orderBy( ['pdate'], ['desc'] )
        //                 .head()
        //                 .value()["slug"]
        const latestslug = _flow(
            _orderBy( ['pdate'], ['desc'] ),
            _head
          )(_indexlist)["slug"]
        // DEBUG :
        // console.log(`_indexlist = `, _indexlist)
        // console.log(`latestslug = `, latestslug)
        
        
        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {
                    const lang = i18n.language.toLowerCase()
                    const ogImg = (place.Images === undefined) ? '':place.Images
                    const pagetitle = `${t('MU000')} | ${t('MU005')} | ${t('MU008')} | ${t('PT6600')} | ${t(place.Name)}`
                    const pagepath = `/${this.props.pageContext.lng}${this.props.pageContext.originalPath}`
                    // console.log(`<Share url=${pagepath} title=${pagetitle} display="row" caption={t('PT0301')}> / this.props.location.href = ${this.props.location.href}`)

                    return (
                        <Layout siteData={{ title:pagetitle , url:this.props.location.href, img:ogImg }}>
                        
                        <div id="PlaceContent" className="body-page ">
                            <Container className="wrapper line-head">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={`/${lang}/map/`}> {t('MU008')} ></Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/${lang}/map/places/`}> {t('PT6500')} ></Breadcrumb.Item>
                                </Breadcrumb>
                            </Container>
                            
                            <main role="main" className="main">

                            {
                                (place.Images) ?
                                <>
                                <Container>
                                    <Row >
                                        <Col className="top-box text-center">
                                        {
                                            (ogImg) ?
                                                <LocalImage name={ogImg.split('.')[0]} attr={{className: "img-fluid", alt:`Go Link`}}/>
                                                : null
                                        }
                                            
                                        </Col>
                                    </Row>
                                </Container>
                                </>
                                : null
                            }


                                <Container className="wrapper line-inter">
                                    <Row>
                                        <Col className="">
                                            <div className="ptitle">{t(place.Name)}</div>
                                            <div className="desp">{t(place.Description)}</div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="morelink">
                                            {
                                                (_.isEmpty(place.Events)) ?  "": <div className="line-head">{t('PT6601')}</div>
                                            } 
                                            
                                            {_.map( place.Events, (eid)=>{
                                                let itemname = (typeof(this.state.eventsList[eid]) === 'undefined' ) ? undefined :  this.state.eventsList[eid]["Name"]
                                                // console.log(`itemname =  ${itemname}`)
                                                let eventname = (typeof(itemname) === 'undefined' ) ? <LocalImage name="loading" attr={{className: "img-fluid", alt:"Loading" }}/> : t(itemname)
                                                let eventlink = (typeof(itemname) === 'undefined' ) ? "#" : (`/map/events/${_t[itemname].match(/[A-Za-z0-9]+/g).join('_').toLowerCase()}`)
                                                return(
                                                    <Link to={eventlink} key={`event-link-${eid}`}>
                                                        { eventname }
                                                    </Link>
                                                )
                                            })
                                            }
                                        </Col>
                                    </Row>

                                    <Row className="share-full">
                                        <Col className="d-flex justify-content-center">
                                            <Share url={pagepath} title={pagetitle} display="row" caption={t('PT0301')}/>
                                        </Col>
                                    </Row>
                                    <NavBtm index="/map/places/" slug={slug} latest={latestslug} />
                                </Container>

                                <Container>
                                    <div className="readmore">
                                        <Row className="psubhead">{t('PT0003')}</Row>
                                        <Row className="plist d-flex align-items-center flex-wrap">
                                            {_.map( _indexlist, (item,i) => {
                                                // console.log(`i = ${i} pts=${this.state.postsToShow} `)
                                                let hs = (i<this.state.postsToShow) ? ' show ' : 'hidden'
                                                let icon = (this.state.ptypeList[item.tid] === undefined) ? "loading_circle" : this.state.ptypeList[item.tid]["IconImage"].split('.')[0] 
                                                return (
                                                    <Link to={`/map/places/${item.slug}`} key={`place-rm-${i}`} className={`col-12 col-md-6 col-lg-3 ${hs}`}>
                                                        <div className="plist-img text-center">
                                                            <LocalImage name={icon} attr={{className: "img-fluid", alt:t(item.name) }} />
                                                        </div>
                                                        <div className="plist-box">
                                                            {t(item.name)} 
                                                        </div>
                                                    </Link>
                                                )}
                                            )}
                                        </Row>
                                    </div>
                                </Container>

                            </main>

                        </div>




                        </Layout>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(PlacePost)

PlacePost.propTypes = {
    data: PropTypes.object.isRequired,
}

export const query = graphql`
query GetPlacePost($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }

    en: allLocale(filter: { lng: { eq: "en", }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
}
`