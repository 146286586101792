import React, { Component } from "react"
import { graphql } from "gatsby"
import _ from 'lodash'
import _head from "lodash/fp/head";
import _reject from "lodash/fp/reject";
import _orderBy from "lodash/fp/orderBy";
import _flow from "lodash/fp/flow";

import {
    Breadcrumb, Row, Col, 
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'
import PropTypes from 'prop-types'
// import Moment from 'react-moment'

import Layout from "../components/layout"
import NewsList from '../components/news/news_list'
import PrismicHTML from '../components/prismic-html'
import Share from '../components/share'
import NavBtm from '../components/navbtm'


class CodexPost extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            postsToShow:4,
            postsToLoad:2,
            indexList: {},
        }
    }

    componentDidMount() {
        // console.log(`this = `, this.props)
        window.addEventListener(`scroll`, this._handleScroll)
        let mq = document.getElementsByClassName('wrapper')[0].offsetWidth
        // console.log(`news-mount - mq = ${mq}`)
        if (mq>1138) this.setState({ postsToShow: 8, postsToLoad: 4 })
        else if (mq>718) this.setState({ postsToShow: 6, postsToLoad: 3 })
        else this.setState({ postsToShow: 4, postsToLoad: 2 })
    }

    componentWillUnmount() {
        window.removeEventListener(`scroll`, this._handleScroll)
    }

    _update() {
        // console.log(`news-update`, this.state.indexList)
        const distanceToBottom = document.querySelector('#footer .base').offsetTop
        if (distanceToBottom < window.scrollY+window.innerHeight  && this.state.postsToShow < _.size(this.state.indexList) ) {
            // console.log(`d to btm: ${distanceToBottom} < w.sY: ${window.scrollY} / ${window.scrollY+window.innerHeight}  && pts: ${this.state.postsToShow} < ${_.size(this.state.indexList)} `)
            this.setState({ postsToShow: this.state.postsToShow + this.state.postsToLoad })
        }
        this.ticking = false
    }

    _handleScroll = () => {
        // console.log(`news-scrollhandle`)
        if (!this.ticking) {
            this.ticking = true
            requestAnimationFrame(() => this._update())
        }
    }


    render() {
        const news = this.props.data.news
        const lang = this.props.pageContext.lng
        const slug = this.props.pageContext.slugId

        // SET UP DATA ////
        // const rawJson = news.raw
        // const newsId = news.id
        // const newsType = news.head.type
        // const newsDate = news.head.datetime
        const newsTitle = news.head.title.text

        // SET UP INDEX ////
        const indexlist = this.props.pageContext.newsIndex
        // console.log(`===indexlist===`, indexlist )
        let _indexlist = []
        // _.map( indexlist, (tobj,type) => {
            _.map( indexlist, (obj) => {
                if ( obj.lang === 'en-gb') obj.lang = 'en'
                if (obj.lang === lang){
                    _indexlist.push(obj)
                }
            })
        // })
        // console.log(`===_indexlist===`, _indexlist )
        // _indexlist = _.chain(_indexlist)
        //     .reject( {'id': news.id} )
        //     .orderBy( ['pdate'], ['desc'] )
        //     .value()
        _indexlist = _flow(
            _reject({'id': news.id}),
            _orderBy( ['pdate'], ['desc'] )
          )(_indexlist)

        if (_.size(this.state.indexList)===0)
            this.setState({ indexList: _indexlist })

        // Set latest record slug /////
        // const latestslug = _.chain(_indexlist)
        //                 .orderBy( ['pdate'], ['desc'] )
        //                 .head()
        //                 .value()["slug"]
        const latestslug = _flow(
            _orderBy( ['pdate'], ['desc'] ),
            _head
          )(_indexlist)["slug"]
        // DEBUG :
        // console.log(`===== this.props =====`, this.props )
        // console.log(`===_indexlist===`, _indexlist )
        
        // console.log(`lang = ${lang} / slug = ${slug} / newsId= ${newsId} / newsType= ${newsType} / newsTitle= ${newsTitle} / newsDate=${newsDate} / rawJson = } `, rawJson.content)

        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {

                    const rawJson = JSON.parse(news.raw)
                    const ogImg = (rawJson.content[0].url) ? rawJson.content[0].url : ''
                    
                    return (
                        <Layout siteData={{title:`${t('MU000')} | ${t('MU006')} | ${rawJson.title[0].text}`, url:this.props.location.href, img:ogImg }}>
                            <div id="CodexContent" className="body-page">
                                <nav role="navigation">
                                    <Container className="wrapper line-head">
                                        <Breadcrumb>
                                            <Breadcrumb.Item href={`/${lang}/codex/`}> {t('MU006')} ></Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Container>
                                </nav>

                                <main role="main" className="main">
                                    <Container className="wrapper particle">
                                        {
                                            (news) ? 
                                                <>
                                                    <Row>
                                                        <Col className="title">
                                                            {newsTitle}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="content">
                                                            <PrismicHTML html={rawJson.content} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="share-full">
                                                        <Col>
                                                            <Share display="row" caption={t('PT0301')} url={`/${lang}/news/${slug}`} title={newsTitle} className="ic-share" />
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                null
                                        }
                                        <NavBtm index="/codex/" slug={slug} latest={latestslug} />
                                    </Container>

                                    {/*--- Read More ---*/}
                                    <Container className="wrapper">
                                        <div className="readmore">
                                            <Row className="psubhead">{t('PT0003')}</Row>
                                            <Row className="plist plistsquare d-flex justify-content-start flex-wrap">
                                                {_.map( _indexlist, (item,i) => {
                                                    // console.log(`i = ${i} pts=${this.state.postsToShow} `)
                                                    let hs = (i<this.state.postsToShow) ? ' show ' : 'hidden'
                                                    return (
                                                        <NewsList key={`news-rm-${item.id}`} news={item} hs={hs}/>
                                                    )}
                                                )}
                                            </Row>
                                        </div>
                                    </Container> 
                                </main>
                            </div>
                        </Layout>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(CodexPost)

CodexPost.propTypes = {
    data: PropTypes.object.isRequired,
}

export const query = graphql`
    query GetCodexPost($lng: String!, $lngReg: String!, $newsId: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }

        news: prismicNews(
            lang: { regex: $lngReg }
            prismicId: { eq: $newsId }
        ) {
            id: prismicId
            lang
            alternate_languages {
                id
                lang
            }
            head: data {
                title {
                    text
                }
                datetime
                type
            }
            raw:dataString
        }
    }
`
