import React, { Component } from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import _ from 'lodash'

import {
    Breadcrumb, 
    Carousel, Row, Col,
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import Share from '../components/share'
import Layout from "../components/layout"
import PrismicHTML from '../components/prismic-html'

const ptype = require('./../util/productcatelist.json')

class ProductPost extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
          open: false,
        };
    }

    render() {
        // To use data, please use below
        const data = this.props.data
        const product = data.product.data
        // const rowData = JSON.parse(data.product.raw)
        // const productContentData = rowData.product_content
        // DEBUG:
        // console.log(`==== Product Content : this.props ==== `, this.props)
        // console.log(product)
        // console.log(rowData)
        // const pageContext = this.props.pageContext
        // rawJson.content[0].url

        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {
                    const lang = i18n.language.toLowerCase()

                    const ogImg = (product.photos[0].img.url) ? product.photos[0].img.url : ''
                    // const ogImg = (product.photos[0] === undefined) ? '':place.Images
                    const pagetitle = `${t('MU000')} | ${t('MU011')} | ${product.product_name.text} `
                    const pagepath = `/${this.props.pageContext.lng}${this.props.pageContext.originalPath}`
                    // console.log(`<Share url=${pagepath} title=${pagetitle} display="row"> / this.props.location.href = ${this.props.location.href}`)

                    const catehash = _.findKey( ptype, { "nameid" : product.series_id } )

                    const manuId = product.manufacturer_id || "TEXT150"
                    const manufacturer_url = t(manuId.replace("TEXT150", "TEXT151"))
                    const manufacturer_email = t(manuId.replace("TEXT150", "TEXT152"))
                    // DEBUG : // Check if manufacturer is null value
                    // console.log(`${pagetitle} , product.manufacturer_id = ${product.manufacturer_id} , replace result =  ${product.manufacturer_id.replace("TEXT150", "TEXT151")}`)
                    // console.log(`product.manufacturer_email = ${product.manufacturer_email} , replace result =  ${product.manufacturer_email.replace("TEXT150", "TEXT151")}`)

                    return (
                        <Layout siteData={{ title:pagetitle , url:this.props.location.href, img:ogImg }}>

                        <div id="ProductContent" className="body-page ">
                            
                            <nav role="navigation">
                                <Container className="wrapper line-head">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href={`/${lang}/products/`}> {t('MU011')} ></Breadcrumb.Item>
                                        <Breadcrumb.Item href={`/${lang}/products/#${catehash}`}> {t(product.type_id)} - {t(product.series_id)} ></Breadcrumb.Item>
                                    </Breadcrumb>
                                </Container>
                            </nav>

                            <main role="main" className="main">

                                <Container className="d-block d-md-none">
                                    <Row>
                                        <Col className="top-box">
                                            <Carousel>
                                                {
                                                    product.photos.map(({img}, i) => {
                                                        return (
                                                            <Carousel.Item key={`productDetail-${i}`}>
                                                                <img className="d-block w-100" alt={`slide-${i}`} src={img.url} />
                                                            </Carousel.Item>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </Col>
                                    </Row>
                                </Container>

                                <Container className="wrapper">
                                    <Row>
                                        <Col md={6} className="d-none d-md-block ">
                                            <Carousel>
                                                {
                                                    product.photos.map(({img}, i) => {
                                                        return (
                                                            <Carousel.Item key={`productDetail-${i}`}>
                                                                <img className="d-block w-100" alt={`slide-${i}`} src={img.url} />
                                                            </Carousel.Item>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </Col>

                                        <Col md={6}>
                                            <Row className="ptitle line-base pb-3 pb-md-5">
                                                <Col>
                                                    {product.product_name.text}
                                                </Col>
                                            </Row>
                                            <div className="data-table">
                                                <Row className="line-base">
                                                    <Col xs={5} className="caption">{t('PT7001')}</Col>
                                                    <Col xs={7}>{t(product.type_id)}</Col>
                                                </Row>
                                                <Row className="line-base">
                                                    <Col xs={5} className="caption">{t('PT7002')}</Col>
                                                    <Col xs={7}>{t(product.series_id)}</Col>
                                                </Row>
                                                <Row className="line-base">
                                                    <Col xs={5} className="caption">{t('PT7003')}</Col>
                                                    <Col xs={7}>{t(product.release_date)}</Col>
                                                </Row>
                                                <Row className="line-base">
                                                    <Col xs={5} className="caption">{t('PT7004')}</Col>
                                                    <Col xs={7}>
                                                    {
                                                        product.prices.map(({currency, currency_value}, i) => {
                                                            return (
                                                                <span key={`price-${i}`}> 
                                                                    {currency} ${(+currency_value).toFixed(2)}
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                    <div className="caption caption-l">{t('PT7005')}</div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col className="desp">
                                                    <div className="msg-wrapper">{product.description.text}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="share-full">
                                        <Col className="py-4 d-flex justify-content-center">
                                            <Share url={pagepath} title={pagetitle} display="row" caption={t('PT0301')} />
                                        </Col>
                                    </Row>
                                    <hr className="d-block d-md-none pb-2"/>
                                </Container>

                                <Container className="wrapper more-details">
                                    <Row className="line-base">
                                        <Col className="cell">
                                            <p>{t('PT7006')}</p>
                                            <p>{t(product.retail_status_id)}</p>
                                            {
                                                product.retailers.map(({retailer_name, retailer_link}, i) => {
                                                    let _rl = ""
                                                    if (retailer_link !== null && retailer_link !== "" && retailer_link !== undefined) {
                                                        _rl = retailer_link.url
                                                    } else {
                                                        _rl = "/#"
                                                    }
                                                    return (
                                                        <a key={`retailer-${i}`} href={_rl} target="_blank" rel="noopener noreferrer">{retailer_name}</a>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Row>

                                    <Row className="line-base">
                                        <Col md={6} className="line-inter cell">
                                            <p>{t('PT7007')}</p>
                                            {
                                                ( typeof manufacturer_url === 'undefined' || manufacturer_url==='N/A') ?
                                                    t(product.manufacturer_id)
                                                    :
                                                    <a href={`${t(manufacturer_url)}`} target="_blank" rel="noopener noreferrer">{t(product.manufacturer_id)}</a>
                                            }
                                        </Col>
                                        <Col md={6} className="cell">
                                            <p>{t('PT7008')}</p>

                                            {
                                                ( typeof manufacturer_email === 'undefined' || manufacturer_email==='N/A') ?
                                                    <span>--</span>
                                                    :
                                                    <a href={`mailto:${t(manufacturer_email)}`} target="_blank" rel="noopener noreferrer">{manufacturer_email}</a>
                                            }
                                        </Col>
                                    </Row>

                                    <Row className="line-base">
                                        <Col md={6} className="line-inter cell">
                                            <div className="caption-b">{t('PT7009')}</div>
                                            <div className="desp">
                                                {/* <PrismicHTML html={productContentData} /> */}
                                                <div className="msg-wrapper">{product.product_content.text}</div>
                                            </div>
                                        </Col>
                                        <Col md={6} className="cell">
                                            <div className="caption-b">{t('PT7010')}</div>

                                            <div className="data-table">
                                            {
                                                product.additional_info.map(({info_name, info_value}, i) => {
                                                    return (
                                                        <Row key={`addInfo-${i}`}>
                                                            <Col xs={4} className="caption">{info_name}:</Col>
                                                            <Col xs={6}>{info_value}</Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="line-base">
                                            <Col className="cell">
                                                <div className="caption-b">
                                                    <span>⚠</span>{t('PT7011')}
                                                </div>
                                                <div className="desp">
                                                    {t(product.warning_id)}
                                                </div>

                                            </Col>
                                        </Row>

                                </Container>
                            </main>
                        </div>




                        </Layout>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(ProductPost)

ProductPost.propTypes = {
    data: PropTypes.object.isRequired,
}

export const query = graphql`
    query GetProduct($lng: String!, $lngReg: String!, $productId: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }

        product: prismicProductDetail(
            lang: { regex: $lngReg }
            prismicId: { eq: $productId }
        ) {
            data {
                product_name {
                    text
                }
                label_type
                tag_id
                type_id
                series_id
                faction_id
                description {
                    text
                }
                warning_id
                manufacturer_id
                photos {
                    img {
                        url
                        
                    }
                }
                additional_info {
                    info_name
                    info_value
                }
                product_content {
                    text
                }
                release_date
                prices {
                    currency
                    currency_value
                }
                retailers {
                    retailer_name
                    retailer_link {
                        url
                    }
                }
                retail_status_id
            }
            raw: dataString
        }
    }
`
