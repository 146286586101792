import React, { Component } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import _reject from "lodash/fp/reject";
import _map from "lodash/fp/map";
import _filter from "lodash/fp/filter";
import _orderBy from "lodash/fp/orderBy";
import _groupBy from "lodash/fp/groupBy";
import _flow from "lodash/fp/flow";

import {
    Dropdown,
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../../util/get-cache-handler'
import { getTrans } from '../../util/get-trans-handler'
import { getAxiosUnits } from '../../util/get-axios-units-handler'
import { getAxiosArmies } from '../../util/get-axios-armies-handler'
import UnitsMenu from '../../components/database/units_menu'
import UnitsList from '../../components/database/units_list'

import Layout from '../../components/layout'
import LocalImage from '../../components/local-image'

import '../../css/database.css'


class DatabaseHeroesPage extends Component {

    constructor(props, context) {
        super(props, context);
        // Don't call this.setState() here!

        this.state = {
            trans: [],
            factionsList: null,
            unitsList: null,
        }

    }

    async componentDidMount() {

        let self = this

        /////// Initial translate for slug //////////
        const enList = (self.props.data.en.edges)
        let _t = await getTrans(enList)
        self.setState({ trans: _t })

        /////// Setup Data //////////
        let unitslist = JSON.parse(localStorage.getItem('unitslist')) || undefined
        let armieslist = JSON.parse(localStorage.getItem('armieslist')) || undefined

        // Get Local Cache
        if ( typeof unitslist === 'undefined' || typeof armieslist === 'undefined' || getCache() ) {
            // console.log(`load ajax`)
            unitslist = await getAxiosUnits({ o:1000 })
            armieslist = await getAxiosArmies({ o:1000 })

            localStorage.setItem('unitslist', JSON.stringify(unitslist))
            localStorage.setItem('armieslist', JSON.stringify(armieslist))
            localStorage.setItem('version', +(new Date()))
        }

        /////// Display Data //////////
        // Setup Faction List
        let factionslist = []
        if ( armieslist ){
            // factionslist = _.chain(armieslist)
            //                 .reject( {'TypeName':'Division'} )
            //                 .map( obj =>{ 
            //                     obj["NameEn"] = _t[obj.Name]
            //                     return obj
            //                 })
            //                 .value()
            factionslist = _flow(
                _reject( {'TypeName':'Division'} ),
                _map( obj => {
                        obj["NameEn"] = _t[obj.Name]
                        return obj
                      })
              )(armieslist)
        }
        // console.log(`==== factionslist ====`,factionslist)

        // Setup Units List
        if ( unitslist && factionslist ){
            // let _unitslist = _.chain(unitslist)
            //                 .filter( { 'TypeName':'Heroes' })
            //                 .map( obj =>{ 
            //                     obj["NameEn"] = _t[obj.Name]
            //                     return obj
            //                 })
            //                 .orderBy( ['Faction','DisplayOrder', 'NameEn'], ['asc','desc', 'asc'] )
            //                 .groupBy( 'Faction' )
            //                 .value()
            let _unitslist = _flow(
                _filter( { 'TypeName':'Heroes' } ),
                _map( obj => {
                        obj["NameEn"] = _t[obj.Name]
                        return obj
                      }),
                _orderBy( ['Faction','DisplayOrder','NameEn'], ['asc','desc','asc'] ),
                _groupBy( 'Faction' )
              )(unitslist)
            // console.log(`==== _unitslist ====`,_unitslist)

            // Display by Group 
            let htmlCompontent = []
            let flist = {}
            let other = { ArmieID:0, Name: "PT0202", NameEn:_t["PT0202"]  }  // Initial faction dropdown list, setup object for units with NO faction type
            _.map(_unitslist, (grouplist,i)  => {
                let id = ( +i > 0 ) ? +grouplist[0]["Faction"] : 0 
                let faction = (_.find(factionslist,{"ArmieID": id })) || other
                flist[id] = faction
                let slug = _t[faction["Name"]].match(/[A-Za-z0-9]+/g).join('_').toLowerCase() || faction["Name"] || faction["ArmieID"]

                // DEBUG :
                // console.log(`${i} - typeof(id) = ${typeof(id)}, id = ${id} / slug = ${slug}/ faction = `, faction)
                
                htmlCompontent.push(
                    <NamespacesConsumer key={`unitlist-faction-ns-${i}`}>
                        {(t, { i18n }) => {
                            return (
                                <>
                                <Container className="wrapper line-top">
                                    <div id={`${slug}`} className="bookmark"></div>
                                    {
                                        (id>0) ? 
                                            <a href={`/${self.props.pageContext.lng}/map/factions/${slug}`} className="psection">
                                                {`${t(faction["Name"])}`}
                                                <LocalImage name={`linkgo`} attr={{className: "linkgo", alt:`Open Link`}}/>
                                            </a>
                                            :
                                            <div className="psection">{`${t(faction["Name"])}`}</div>
                                    }
                                    <UnitsList unitslist={grouplist} typeorder={["Heroes"]} key={`unitlist-faction-data-${i}`} enTran={_t}/>
                                </Container>
                                </>
                            )
                        }}
                    </NamespacesConsumer>
                )
            })
            flist = _.sortBy(flist, ['NameEn'])
            // console.log(`==== flist ====`, flist)

            // Resort "other" to bottom of the list
            let _flist = _.sortBy(flist, (obj)=>{
                return obj.ArmieID > 0 ? 0:1
            })
            // console.log(`==== _flist ====`, _flist)

            self.setState({ 
                unitsList: htmlCompontent,
                factionsList: _flist,
            })
        }

        if (typeof window !== 'undefined') {
            const urlHash = window.location.hash.split('#')[1]
            // console.log(`did mount urlHash - `, urlHash)
            // Scroll to bookmark by #hash ////
            let bm = document.getElementById(urlHash)
            if (bm) window.scrollTo(0, bm.offsetTop)
        }

    }

    render() {
        // let _t = this.state.trans
        // console.log(`this.props.item =  `,this.props )

        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                // const lang = i18n.language.toLowerCase()

                return (
                    
                    <Layout  key={`database-layout`} siteData={ { title:`${t("MU000")} | ${t("MU007")} | ${t("PT5001")} `, url:this.props.location.href } }>
                        <div id="DatabaseList" className="body-page ">

                            <nav role="navigation"> 
                                <Container className="wrapper line-head">
                                    <Row>
                                        <Col xs={9} lg={12} className="d-flex align-items-center" >
                                            <div className="phead">{t('MU007')}</div>
                                        </Col>
                                        <UnitsMenu mode="dropdown"/>
                                    </Row>
                                </Container>
                                <Container className="wrapper line-bottom-md">
                                    <Row>
                                        <Col>
                                            <UnitsMenu mode="tab" active="heroes"/>
                                        </Col>
                                    </Row>
                                </Container>
                            </nav>
                            
                            <main role="main" className="main">
                            
                                <Container className="wrapper line-base">
                                    <Row className="pt-4">
                                        <Col className="d-flex justify-content-between justify-content-md-start">
                                            <div className="ptitle">{t('PT5001')}</div>

                                            <Dropdown className="dropdown-bookmark d-flex justify-content-end" alignRight>
                                                <Dropdown.Toggle variant="bookmark">
                                                    <LocalImage name="btn-godown" attr={{className: "icon", alt:"Lang"}} fluid={true} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {_.map( this.state.factionsList, (faction,i) =>{
                                                        let slug =  faction.NameEn.match(/[A-Za-z0-9]+/g).join('_').toLowerCase() || faction.ArmieID
                                                        // console.log(`slug = ${slug} _t(faction.Name) = ${_t[faction.Name]}`)
                                                        return(
                                                            <Dropdown.Item key={`unitslist-bmmenu-${i}`} href={`#${slug}`}>{`${t(faction.Name)}`}</Dropdown.Item>
                                                        )
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Container>

                                {
                                    (this.state.unitsList==null) ?
                                        <div className="loading"></div>
                                        :
                                        this.state.unitsList
                                }

                            </main>
                            
                        </div>
                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(DatabaseHeroesPage)

export const query = graphql`
    query getDatabaseHeroesPageLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }

        en: allLocale(filter: { lng: { eq: "en", }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
