import React, { Component } from 'react'
import _ from 'lodash'
import { graphql } from "gatsby"
import {
    Row, Col,
    Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import Layout from '../components/layout'

import './../css/pages.css'

class ContactPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            notice:{},
            noticeHTML: [],
            formInput: false,
            formValid: null
        }

        this.formURL = this.props.data.site.siteMetadata.contactFormApiEndPoint || ""

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleInputChange(e) {
        const field = e.target.name
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

        this.setState({
            formValid: this.validateField(field, value)
        })
    }

    validateField(fieldName, value) {
        let html = []
        let notice = { 
            name:'', 
            email:'',
            message:'',
        }
        switch (fieldName) {
            case 'name':
                notice['name'] = value.length >= 2 ? '' : 'Name is too short'
                break;

            case 'email':
                notice['email'] = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? '' : 'Email is invalid'
                break;

            case 'message':
                notice['message'] = value.length >= 6 ? '' : 'Message is too short';
                break;

            default: break;
        }
        // console.log(`validateField / notice = `, notice)
        this.setState({ notice: notice })

        for (var key in this.state.notice) {
            html.push( (this.state.notice[key]) ? <i key={key}>{this.state.notice[key]}<br/></i> : null )
        }

        this.setState({ noticeHTML : _.union(html) })

        // console.log(`validateField return = ${this.state.notice.name} | ${this.state.notice.email} | ${this.state.notice.message}`)

        return (
            this.state.notice.name === '' &&
            this.state.notice.email === '' &&
            this.state.notice.message === ''
        )
    }

    handleSubmit(event) {
        event.preventDefault()
        const form = event.target
        const data = new FormData(form)
        let checking = null
        let objJSON = {}

        for (let name of data.keys()) {
            if (checking === true || checking === null ) {
                const input = form.elements[name].value
                checking = this.validateField(name,input)
                objJSON[name] = (checking) ? input : false
            }
        }

        if (checking){
            
            fetch( this.formURL, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(objJSON),
            })
            .then(
                // (res) => console.log(res),
                (error) => console.log(error)
            )

            this.setState({ formInput: true })
        }
        


    }

    render() {

        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {

                    return (
                        <Layout siteData={ { title:`${t("TEXT000109")} | ${t("TEXT000010")}`, url:this.props.location.href } }>
                            <div id="bodyContact" className="body-page">
                                <Container className="wrapper">

                                    <main role="main" className="main">

                                        <Row className="justify-content-md-center">
                                            <Col md="8" lg="4">

                                                <h2>{t('TEXT000801')}</h2>

                                                <div hidden={!this.state.formInput}>
                                                    <hr/>
                                                    <p className="mt-5 mb-5">{t('TEXT180005')} </p>
                                                    <hr/>
                                                </div>

                                                <form id="contact-form" onSubmit={this.handleSubmit}  hidden={this.state.formInput}>
                                                    <div className="form-group">
                                                        <label htmlFor="name" className="d-block">
                                                            {t('TEXT000803')} <span className="float-right"> {t('TEXT000802')} </span>
                                                        </label>
                                                        <input name="name" type="text" placeholder="" className="form-control" aria-describedby="name"  required
                                                            value={this.state.name} onChange={this.handleInputChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="email"> {t('TEXT000804')} </label>
                                                        <input name="email" type="email" placeholder="" className="form-control" aria-describedby="email"  required
                                                            value={this.state.email} onChange={this.handleInputChange}  />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="phone"> {t('TEXT000805')} </label>
                                                        <input name="phone" type="tel" placeholder="" className="form-control" aria-describedby="phone"
                                                            value={this.state.phone} onChange={this.handleInputChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="message"> {t('TEXT000806')} </label>
                                                        <textarea name="message" placeholder="" row="10" className="form-control" aria-describedby="message" required
                                                            value={this.state.message} onChange={this.handleInputChange} ></textarea>
                                                    </div>
                                                    <div className="panel panel-default"></div>
                                                    <div className="error"> {this.state.noticeHTML} </div>
                                                    <button type="submit" className="btn btn-block" disabled={!this.state.formValid}> {t('TEXT000807')} </button>
                                                </form>



                                            </Col>
                                        </Row>
                                    </main>




                                </Container>
                            </div>

                        </Layout>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(ContactPage)

export const query = graphql`
query contactLang($lng: String!) {
    site {
        siteMetadata {
            contactFormApiEndPoint
        }
    }

    locales: allLocale(filter: {lng: {eq: $lng }, ns: {eq: "messages" } }) {
    ...LocaleFragment
    }
}
`
