import React, { Component } from 'react'
// import _ from 'lodash'

import {
    Row, Col
} from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
import { Link } from '@sckit/gatsby-plugin-i18next'

class NavBtm extends Component {

    render() {
        
        const t = this.props.t
        // const _t = this.props.trans
        const index = this.props.index
        const slug = this.props.slug
        const latestslug = this.props.latest

        // console.log(`===== Nav Bottom > this.props ====`)
        // console.log(this.props)
        // console.log(`index = ${index} slug = ${slug} latestslug = ${latestslug} `)

        return (
            <>
                <Row>
                    <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 4, offset: 4 }} className="navbottom">
                        <Link to={`${index}${latestslug}`}>{t('PT0004')}</Link>
                        <Link to={`${index}${slug}#top`} className="cen">{t('PT0005')}</Link>
                        <Link to={index}>{t('PT0006')}</Link>
                    </Col>
                </Row>
            </>
        )
    }
}

export default withNamespaces()(NavBtm)
