import React, { Component } from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import _ from 'lodash'
// import moment from 'moment'

import {
    Carousel,
    Breadcrumb,
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next, Link } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../util/get-cache-handler'
import {getTrans} from '../util/get-trans-handler'
import UnitsList from '../components/database/units_list'
import { getAxiosArmies } from '../util/get-axios-armies-handler'
import { getAxiosUnits } from '../util/get-axios-units-handler'

import LocalImage from '../components/local-image'
import Share from '../components/share'
import Layout from "../components/layout"

import '../css/database.css'

class UnitPost extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            trans: [],
            unit : null,
            moreLink : null,
        }
    }

    async componentDidMount() {

        let self = this

        /////// Initial translate for slug //////////
        const enList = (self.props.data.en.edges)
        let _t = await getTrans(enList)
        self.setState({ trans: _t })

        /////// Setup Data //////////
        let unitslist = JSON.parse(localStorage.getItem('unitslist')) || undefined
        let armieslist = JSON.parse(localStorage.getItem('armieslist')) || undefined
        
        // Get Local Cache
        if (typeof unitslist === 'undefined' || typeof armieslist === 'undefined' || getCache() ) {
            // console.log(`load ajax`)
            unitslist = await getAxiosUnits({ o:1000 })
            armieslist = await getAxiosArmies({ o:1000 })

            localStorage.setItem('unitslist', JSON.stringify(unitslist))
            localStorage.setItem('armieslist', JSON.stringify(armieslist))
            localStorage.setItem('version', +(new Date()))
        }

        /////// Display Data //////////
        if ( unitslist && armieslist ){
            
            // Setup data for page
            let _id = self.props.pageContext.unitId
            let unit = _.find( unitslist, { UnitID : _id })
            
            let facObj = _.find( armieslist , { ArmieID : +unit.Faction })
            let divObj = _.find( armieslist , { ArmieID : +unit.Division })
            // console.log(`Get data : facObj - ${typeof facObj} divObj - ${typeof divObj} `, )

            unit["factionName"] = (typeof facObj!=='undefined') ? facObj.Name : ""
            unit["divisionName"] = (typeof divObj!=='undefined') ? divObj.Name : ""

            // console.log(`unit ＝ `,unit)
            self.setState({
                unit: unit,
            })



            // Setup data for read more link
            let htmlCompontent = []
            let sections = {
                'PT5102' : 'RelatedHeroes',
                'PT5103' : 'RelatedInfantry',
                'PT5104' : 'RelatedSA',
                'PT5105' : 'RelatedOthers',
            }

            _.map( sections, (item, g11n) =>{
                if ( !(_.isEmpty(unit[item])) ){
                    let _unitslist = _.map( unit[item], id => {
                        let obj = _.find( unitslist, { UnitID : Number(id) })
                        return obj
                    })
                    _unitslist = _.remove( _unitslist, undefined )

                    if ( !(_.isEmpty(_unitslist)) ) {
                        htmlCompontent.push(
                            <NamespacesConsumer key={`unit-morelink-ns-${item}`}>
                                {(t, { i18n }) => {
                                    // console.log(_unitslist)
                                    return (
                                        <>
                                        <div key={`unit-morelink-head-${item}`} className="section-line-head">{`${t(g11n)}`}</div>
                                        <UnitsList key={`unit-morelink-data-${item}`} unitslist={_unitslist} enTran={_t}/>
                                        </>
                                    )
                                }}
                            </NamespacesConsumer>
                        )
                    }
                    self.setState({moreLink: htmlCompontent})
                }
            })



            self.setState({moreLink: htmlCompontent})
        }

    }

    render() {
        let _t = this.state.trans
        const lang = this.props.pageContext.lng
        let unit = this.state.unit || this.props.pageContext.unit

        // DEBUG:
        // console.log(` ==== UnitPost Render : this.props ==== `, this.props)
        // console.log(`unit / this.state.unit || this.props.pageContext.unit = `, unit)

        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {
                    const ogImg = ( unit !== null && unit.hasOwnProperty('Illustrations') ) ? unit.Illustrations[0]: ''
                    const pagepath = this.props.location.pathname
                    const pagetitle = `${t('MU000')} | ${t('MU005')} | ${t('MU007')} | ${t(unit.Name)}`
                    // console.log(`<Share url={${pagepath}} title={${pagetitle}} display="row" caption={t('PT0301')}/>`)

                    const facName = ( unit.factionName === undefined ) ? '' : unit.factionName
                    const divName = ( unit.divisionName === undefined ) ? '' : unit.divisionName 
                    // console.log(`facName - ${facName} divName - ${divName}`)

                    return (
                        <Layout siteData={{ title:pagetitle , url:this.props.location.href, img:ogImg }}>

                            <div id="DatabaseContent" className="body-page ">
                                <Container className="wrapper line-head">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href={`/${lang}/database/`}> {t('MU007')} ></Breadcrumb.Item>
                                    </Breadcrumb>
                                </Container>

                                <main role="main" className="main">
                                    <Container className="line-base">
                                        <Row>
                                            <Col cs={12} md={5} className="top-box text-center">
                                                {
                                                    ( unit === null || typeof unit.Illustrations === 'undefined' || unit.Illustrations.length <= 0 ) ?
                                                        <LocalImage name={`logo_arw`} attr={{className: "img-fluid p-4", alt:`ARW`}}/>
                                                        :
                                                        ( unit.Illustrations.length > 1 ) ?
                                                            <Carousel>
                                                                {_.map(unit.Illustrations,(item,i)=>{
                                                                    let imgname = (item) ? item.split('.')[0] : "loading_circle"
                                                                    return(
                                                                        <Carousel.Item key={`unit-content-ill-${i}`}>
                                                                            <LocalImage name={imgname} attr={{className: "img-fluid", alt:`${unit.Name} Art ${i}`}}/>
                                                                        </Carousel.Item>
                                                                    )
                                                                })}
                                                            </Carousel>
                                                            :
                                                            // null
                                                            <LocalImage name={unit.Illustrations[0].split('.')[0]} attr={{className: "img-fluid", alt:`${unit.Name} Art `}}/>
                                                }
                                            </Col>
                                            <Col xs={12} md={{ span: 6, offset: 1 }}>
                                                <div className="data-table">
                                                    <Row className="line-base">
                                                        <Col className="ptitle">{t(unit.Name)}</Col>
                                                    </Row>
                                                    {
                                                        ( facName === '' ) ?
                                                            null
                                                            :
                                                            <Row className="line-base-md pt-3 pb-md-3">
                                                                <Col className="caption" xs={4}>{t('PT5100')}</Col>
                                                                <Col xs={8}>
                                                                    <Link to={`/map/factions/${_t[facName].match(/[A-Za-z0-9]+/g).join('_').toLowerCase()}`}>{t(facName)}</Link>
                                                                </Col>
                                                            </Row>
                                                    }
                                                    {
                                                        ( divName === '' ) ?
                                                            null
                                                            :
                                                            <Row className="line-base pb-3 pt-md-3">
                                                                <Col className="caption" xs={4}>{t('PT5101')}</Col>
                                                                <Col xs={8}>
                                                                    <Link to={`/map/divisions/${_t[divName].match(/[A-Za-z0-9]+/g).join('_').toLowerCase()}`}>{t(divName)}</Link>
                                                                </Col>
                                                            </Row>
                                                    }
                                                    <Row>
                                                        <Col className="col-field desp">{t(unit.Description)}</Col>
                                                    </Row>
                                                    <Row className="share-side">
                                                        <Col className="col-field d-flex justify-content-center">
                                                            <Share url={pagepath} title={pagetitle} display="row" caption={t('PT0301')}/>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>


                                    {/* <Container className="wrapper line-base">
                                        <Row>
                                            <Col className="morelink">
                                                {
                                                    (this.state.moreLink==null) ?
                                                        <div className="loading"></div>
                                                        :
                                                        this.state.moreLink
                                                }
                                            </Col>
                                        </Row>
                                    </Container> */}


                                </main>

                            </div>




                        </Layout>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(UnitPost)

UnitPost.propTypes = {
    data: PropTypes.object.isRequired,
}

export const query = graphql`
query GetUnitPost($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }

    en: allLocale(filter: { lng: { eq: "en", }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
}
`
