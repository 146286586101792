import React, { Component } from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import _ from 'lodash'
import _reject from "lodash/fp/reject";
import _orderBy from "lodash/fp/orderBy";
import _flow from "lodash/fp/flow";

import {
    Breadcrumb, 
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next, Link } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../util/get-cache-handler'
// import { getTrans } from '../util/get-trans-handler'
import { getAxiosArmies } from '../util/get-axios-armies-handler'
import { getAxiosUnits } from '../util/get-axios-units-handler'

import LocalImage from '../components/local-image'
import Share from '../components/share'
import Layout from "../components/layout"

import '../css/faction-post.css'

class FactionPost extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            // trans: [],
            // indexList : [],
            faction : [],
            rmFactions: [],
        };
    }

    async componentDidMount() {

        let self = this
        
        /////// Initial translate for slug //////////
        // const enList = (self.props.data.en.edges)
        // let _t = await getTrans(enList)
        // self.setState({ trans: _t })
        
        /////// Setup Data //////////
        let armieslist = JSON.parse(localStorage.getItem('armieslist')) || undefined
        let unitslist = JSON.parse(localStorage.getItem('unitslist')) || undefined

        // Get Local Cache
        if ( typeof armieslist === 'undefined' || typeof unitslist === 'undefined' || getCache() ) {
            // console.log(`load ajax`)
            armieslist = await getAxiosArmies({ o:1000 })
            unitslist = await getAxiosUnits({ o:1000 })

            localStorage.setItem('armieslist', JSON.stringify(armieslist))
            localStorage.setItem('unitslist', JSON.stringify(unitslist))
            localStorage.setItem('version', +(new Date()))
        }
        
        /////// Display Data //////////
        if ( armieslist && unitslist ){
            // Setup Faction Data
            let _id = self.props.pageContext.factionId
            let faction = _.find( armieslist, {'ArmieID':_id})
            // let indexlist = _.reject( armieslist, {'TypeName':'Division', 'ArmieID':_id} )
            // let indexlist =  _.chain(armieslist)
            //             .reject( {'TypeName':'Division', 'ArmieID':_id} )
            //             .orderBy( ['slug'], ['asc'] )
            //             .value()
            // console.log(`faction = `, faction)

            // Setup related units
            let _unitslist = _.filter( unitslist, {'Faction': _id} )
            // console.log(`_unitslist (${_.size(_unitslist)}) = `, _unitslist)

            self.setState({
                // indexList: indexlist,
                faction: faction,
                rmFactions : _.size(_unitslist),
            })
        }

        
    }

    render() {

        const slug = this.props.pageContext.slugId
        let faction = this.state.faction || this.props.pageContext.faction
        // let indexlist = this.state.indexList
        const indexlist = this.props.pageContext.factionIndex

        // Handle places list for read more /////
        // let _indexlist = _.chain(indexlist)
        //                 .reject( {'id': faction.ArmieID} )
        //                 .orderBy( ['slug'], ['asc'] )
        //                 .value()
        let _indexlist = _flow(
            _reject({'id': faction.ArmieID}),
            _orderBy( ['slug'], ['asc'] )
          )(indexlist)

        // DEBUG :
        // console.log(`==== Faction : this.props ==== `, this.props)
        // console.log(`faction = `, faction)
        // console.log(`_indexlist = `, _indexlist)
        

        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {

                    const lang = i18n.language.toLowerCase()

                    const ogImg = faction.Logo || ''
                    const pagepath = this.props.location.pathname
                    const pagetitle = `${t('MU000')} | ${t('MU005')} | ${t('MU008')} | ${t('PT6400')} | ${t(faction.Name)} `
                    // console.log(`<Share url=${pagepath} title=${pagetitle} display="row" caption={t('PT0301')}> / this.props.location.href = ${this.props.location.href}`)

                    return (
                        <Layout siteData={{ title:pagetitle , url:this.props.location.href, img:ogImg }}>

                        <div id="FactionContent" className="body-page armiespage">
                            <Container className="wrapper line-head">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={`/${lang}/map/`}> {t('MU008')} ></Breadcrumb.Item>
                                    <Breadcrumb.Item href={`/${lang}/map/factions/`}> {t('PT6300')} ></Breadcrumb.Item>
                                </Breadcrumb>
                            </Container>
                            
                            <main role="main" className="main">

                                <Container className="wrapper line-inter">
                                    <Row >
                                        <Col xs={12} md={4} className="top-box text-center">
                                        {
                                            (ogImg === '') ?
                                                null
                                                : 
                                                <LocalImage name={`${ogImg.split('.')[0]}`} attr={{className: "img-fluid", alt:`Go Link`}}/>
                                        }
                                        <div className="ptitle d-block d-md-none">{t(faction.Name)}</div>
                                        </Col>

                                        <Col xs={12} md={8}>
                                            <div className="ptitle d-none d-md-block">{t(faction.Name)}</div>
                                            <div className="desp">{t(faction.Description)}</div>

                                            <div className="morelink">
                                                {
                                                    (this.state.rmFactions>0) ?
                                                        <Link to={`/database/faction/#${slug}`}>{t('PT6401')} <LocalImage name={`linkgo`} attr={{className: "linkgo", alt:`Go Link`}}/> </Link>
                                                        :
                                                        null
                                                }
                                                {/* <Link to={`/map/places/#${slug}`}>{t('PT6402')} <LocalImage name={`linkgo`} attr={{className: "linkgo", alt:`Go Link`}}/> </Link>
                                                <Link to={`/map/events/#${slug}`}>{t('PT6403')} <LocalImage name={`linkgo`} attr={{className: "linkgo", alt:`Go Link`}}/> </Link> */}
                                            </div>

                                            <div className="share-side d-flex justify-content-center">
                                                <Share url={pagepath} title={pagetitle} display="row" caption={t('PT0301')}/>
                                            </div>

                                        </Col>
                                    </Row>
                                </Container>

                                <Container>
                                    <div className="readmore">
                                        <Row className="psubhead">{t('PT0003')}</Row>
                                        <Row className="plist d-flex align-items-center flex-wrap">
                                            {_indexlist.map( (item,i) => {
                                                // console.log(`item.slug = ${item.slug}`)
                                                let slug = t(item.slug)
                                                let icon = item.logo.split('.')[0] ||  "loading_circle" 
                                                return (
                                                    <a href={`/${lang}/map/factions/${slug}`} key={`faction-rm-${i}`} className="col-12 col-md-6 col-lg-3" rel="noopener ">
                                                        <div className="plist-img text-center">
                                                            <LocalImage name={icon} attr={{className: "img-fluid", alt:t(item.name)}} />
                                                        </div>
                                                        <div className="plist-box">
                                                            {t(item.name)} 
                                                        </div>
                                                    </a>
                                                )}
                                            )}
                                        </Row>
                                    </div>
                                </Container>

                            </main>

                        </div>




                        </Layout>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(FactionPost)

FactionPost.propTypes = {
    data: PropTypes.object.isRequired,
}

export const query = graphql`
query GetFactionPost($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
}
`