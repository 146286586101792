// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-sckit-redirect-js": () => import("./../../@sckit/redirect.js" /* webpackChunkName: "component---cache-sckit-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-codex-js": () => import("./../../../src/pages/codex.js" /* webpackChunkName: "component---src-pages-codex-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-creator-js": () => import("./../../../src/pages/creator.js" /* webpackChunkName: "component---src-pages-creator-js" */),
  "component---src-pages-database-divisions-js": () => import("./../../../src/pages/database/divisions.js" /* webpackChunkName: "component---src-pages-database-divisions-js" */),
  "component---src-pages-database-faction-js": () => import("./../../../src/pages/database/faction.js" /* webpackChunkName: "component---src-pages-database-faction-js" */),
  "component---src-pages-database-heroes-js": () => import("./../../../src/pages/database/heroes.js" /* webpackChunkName: "component---src-pages-database-heroes-js" */),
  "component---src-pages-database-index-js": () => import("./../../../src/pages/database/index.js" /* webpackChunkName: "component---src-pages-database-index-js" */),
  "component---src-pages-database-infantry-js": () => import("./../../../src/pages/database/infantry.js" /* webpackChunkName: "component---src-pages-database-infantry-js" */),
  "component---src-pages-database-others-js": () => import("./../../../src/pages/database/others.js" /* webpackChunkName: "component---src-pages-database-others-js" */),
  "component---src-pages-database-sa-js": () => import("./../../../src/pages/database/sa.js" /* webpackChunkName: "component---src-pages-database-sa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-divisions-js": () => import("./../../../src/pages/map/divisions.js" /* webpackChunkName: "component---src-pages-map-divisions-js" */),
  "component---src-pages-map-europe-js": () => import("./../../../src/pages/map/europe.js" /* webpackChunkName: "component---src-pages-map-europe-js" */),
  "component---src-pages-map-events-js": () => import("./../../../src/pages/map/events.js" /* webpackChunkName: "component---src-pages-map-events-js" */),
  "component---src-pages-map-factions-js": () => import("./../../../src/pages/map/factions.js" /* webpackChunkName: "component---src-pages-map-factions-js" */),
  "component---src-pages-map-index-js": () => import("./../../../src/pages/map/index.js" /* webpackChunkName: "component---src-pages-map-index-js" */),
  "component---src-pages-map-places-js": () => import("./../../../src/pages/map/places.js" /* webpackChunkName: "component---src-pages-map-places-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-setting-js": () => import("./../../../src/pages/setting.js" /* webpackChunkName: "component---src-pages-setting-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-codex-post-js": () => import("./../../../src/templates/codex-post.js" /* webpackChunkName: "component---src-templates-codex-post-js" */),
  "component---src-templates-division-post-js": () => import("./../../../src/templates/division-post.js" /* webpackChunkName: "component---src-templates-division-post-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-templates-faction-post-js": () => import("./../../../src/templates/faction-post.js" /* webpackChunkName: "component---src-templates-faction-post-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-place-post-js": () => import("./../../../src/templates/place-post.js" /* webpackChunkName: "component---src-templates-place-post-js" */),
  "component---src-templates-product-post-js": () => import("./../../../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-unit-post-js": () => import("./../../../src/templates/unit-post.js" /* webpackChunkName: "component---src-templates-unit-post-js" */)
}

