import React, { Component } from 'react'
import { graphql } from 'gatsby'
// import _ from 'lodash'
import _map from "lodash/fp/map";
import _orderBy from "lodash/fp/orderBy";
import _flow from "lodash/fp/flow";

import {
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../../util/get-cache-handler'
import { getTrans } from '../../util/get-trans-handler'
import { getAxiosUnits } from '../../util/get-axios-units-handler'
import UnitsMenu from '../../components/database/units_menu'
import UnitsList from '../../components/database/units_list'

import Layout from '../../components/layout'
// import LocalImage from '../../components/local-image'

import '../../css/database.css'


class DatabasePage extends Component {

    constructor(props, context) {
        super(props, context);
        // Don't call this.setState() here!

        this.state = {
            trans: [],
            unitsList: null,
        }

    }

    async componentDidMount() {

        let self = this

        /////// Initial translate for slug //////////
        const enList = (self.props.data.en.edges)
        let _t = await getTrans(enList)
        self.setState({ trans: _t })
        
        /////// Setup Data //////////
        let unitslist = JSON.parse(localStorage.getItem('unitslist')) || undefined

        // Get Local Cache
        if ( typeof unitslist === 'undefined' || getCache() ) {
            // console.log(`load ajax`)
            unitslist = await getAxiosUnits({ o:1000 })

            localStorage.setItem('unitslist', JSON.stringify(unitslist))
            localStorage.setItem('version', +(new Date()))
        }

        // console.log(`==== unitslist** ====`,unitslist)
        /////// Display Data //////////
        if ( unitslist ){
            // let _unitslist = _.chain(unitslist)
            //                 .map( obj =>{ 
            //                     obj["NameEn"] = _t[obj.Name]
            //                     return obj
            //                 })
            //                 .orderBy( ['DisplayOrder', 'NameEn'], ['desc', 'asc'] )
            //                 // DEBUG ：
            //                 // .map( obj =>{
            //                 //     console.log(`DO ${obj.DisplayOrder}, PD: ${obj.PublishDate}, ${obj["NameEn"]} - ID : ${obj.UnitID} `)
            //                 //     return obj
            //                 // })
            //                 .value()
            let _unitslist = _flow(
                _map( obj => {
                        obj["NameEn"] = _t[obj.Name]
                        return obj
                      }),
                _orderBy( ['DisplayOrder','NameEn'], ['desc','asc'] )
              )(unitslist)
            // console.log(`==== _unitslist ====`,_unitslist)
            self.setState({unitsList: <UnitsList unitslist={_unitslist} mode="all" enTran={_t}/> })
        }
    }

    render() {
        // let _t = this.state.trans
        // console.log(`this.props.item =  `,this.props )

        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                // const lang = i18n.language.toLowerCase()

                return (
                    
                    <Layout  key={`database-layout`} siteData={ { title:`${t("MU000")} | ${t("MU007")} | ${t("PT5000")} `, url:this.props.location.href } }>
                        <div id="DatabaseList" className="body-page ">
                            
                            <nav role="navigation"> 
                                <Container className="wrapper line-head">
                                    <Row>
                                        <Col xs={9} lg={12} className="d-flex align-items-center" >
                                            <div className="phead">{t('MU007')}</div>
                                        </Col>
                                        <UnitsMenu mode="dropdown"/>
                                    </Row>
                                </Container>
                                <Container className="wrapper line-bottom-md">
                                    <Row>
                                        <Col>
                                            <UnitsMenu mode="tab" active="all"/>
                                        </Col>
                                    </Row>
                                </Container>
                            </nav>
                            
                            <main role="main" className="main">
                            
                                <Container className="line-base">
                                    <Row className="pt-4">
                                        <Col xs={9} className="ptitle">
                                            {t('PT5000')}
                                        </Col>
                                        <Col xs={3}>
                                        </Col>
                                    </Row>

                                    {
                                        (this.state.unitsList==null) ?
                                            <div className="loading"></div>
                                            :
                                            this.state.unitsList
                                    }
                                </Container>

                            </main>
                            
                        </div>
                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(DatabasePage)

export const query = graphql`
    query getDatabaseAllPageLang($lng: String!) {
        locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }

        en: allLocale(filter: { lng: { eq: "en", }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
